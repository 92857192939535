<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .service-desc-header
    .service-desc-header__content
      .service-desc-header__title.c-gray100.mb-2 Кредитный потенциал для&nbsp;бизнеса
      .service-desc-header__description.c-gray100 Узнайте одобренную сумму и&nbsp;получите кредит по&nbsp;ставке на&nbsp;3%&nbsp;ниже
    .service-desc-header__icon
      img(src="~@/assets/96/rent4.svg")

  .px-4
    .t-h3-mobile.c-gray100.mt-10.mb-5 Преимущества
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="timer-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Расчёт онлайн за&nbsp;3&nbsp;минуты
        .t-middle.c-gray80 В интернет-банке и&nbsp;мобильном приложении СберБизнес, а для ИП — в&nbsp;СберБанк Онлайн
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="file-document")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Без открытия счёта и&nbsp;документов
        .t-middle.c-gray80 Расчёт кредитного потенциала не&nbsp;является заявкой на&nbsp;кредит
    .d-flex
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="percent-circle")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Ставка по кредиту ниже на&nbsp;3%
        .t-middle.c-gray80 Есть время на&nbsp;принятие решения о&nbsp;подаче заявки на&nbsp;кредит

    .t-h3-mobile.c-gray100.mt-10.mb-6 Порядок действий
    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="account-tie")
          .icon-in-circle__overlay-icon
            .icon.icon-check-circle-green--colored
      .service-page-numbered-card__content
        .t-normal.c-gray100 Выберите ваш статус
      .service-page-numbered-card__number.c-gray10 1

    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="key")
      .service-page-numbered-card__content
        .t-normal.c-gray100 Авторизуйтесь в СберБизнес или&nbsp;в&nbsp;СберБанк Онлайн
      .service-page-numbered-card__number.c-gray10 2

    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="file-document")
      .service-page-numbered-card__content
        .t-normal.c-gray100 Отправьте заявку на&nbsp;расчет кредита
      .service-page-numbered-card__number.c-gray10 3

    .t-h3-mobile.c-gray100.mt-12 Кредит предоставляет ПАО СберБанк
    img.mt-6(src="~@/assets/logos/sber-logo.svg")

  credit-potential-apply-dialog(
    ref="creditPotentialApplyDialog"
    @click:company="clickCompany(assetId)"
    @click:ip="clickIp(assetId)"
  )

  template(#footer)
    ui-btn.ui-append-icon.mb-3(
      large
      primary
      width="100%"
      @click="clickSBClient(assetId)"
    )
      | Клиент СберБизнес
      ui-icon.ui-append-icon__icon(name="open-in-new")
    ui-btn.ui-append-icon(
      large
      primary
      width="100%"
      @click="$refs.creditPotentialApplyDialog.open()"
    )
      | Не клиент СберБизнес
      ui-icon.ui-append-icon__icon(name="open-in-new")
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import CreditPotentialApplyDialog from '@/components/services/CreditPotentialApplyDialog.vue';
  import { UiBtn, UiIcon } from '@/uikit/';

  import { clickSBClient, clickCompany, clickIp } from '@/libs/credit-potential-links';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, CreditPotentialApplyDialog },
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },

    methods: {
      clickSBClient,
      clickCompany,
      clickIp,
    },
  };
</script>
