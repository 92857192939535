<template lang="pug">
v-card.base-dialog-card(:class="classes" v-bind="$attrs")
  .sh-1(v-if="!noHeader")
    v-toolbar.px-2(flat)
      v-toolbar-title.t-h3-mobile.mb-0 {{ title }}
      v-spacer
      ui-btn(icon plain @click="cancel")
        ui-icon(name="close")

    .pl-6.pb-4.pr-16(v-if="$slots.description")
      slot(name="description")
        .text--middle.c-text {{ description }}

  .base-dialog-card__content
    slot

  .base-dialog-card__footer
    slot(name="footer")

    .base-dialog-card__footer-actions
      .base-dialog-card__btn-cancel
        ui-btn.px-4(text @click="cancel") {{ cancelText }}
      .base-dialog-card__btn-confirm
        slot(name="confirm")
          ui-btn(
            large
            primary
            :width="verticalButtons ? '100%' : undefined"
            @click="confirm"
          ) {{ confirmText }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    name: 'asset-dialog-base-card',
    components: { UiBtn, UiIcon },
    inheritAttrs: false,

    props: {
      title: { type: String, default: 'Диалог' },
      description: { type: String, default: '' },
      cancelText: { type: String, default: 'Отмена' },
      confirmText: { type: String, default: 'ОК' },
      noHeader: { type: Boolean, default: false },
      verticalButtons: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return {
          'base-dialog-card--vertical-buttons': this.verticalButtons,
        };
      },
    },

    methods: {
      cancel() {
        this.$emit('click:cancel');
      },

      confirm() {
        this.$emit('click:confirm');
      },
    },
  };
</script>

<style lang="scss">
  .base-dialog-card {
    .base-dialog-card__footer {
      padding: 24px 32px;
      background-color: c('light', 'light');
    }

    .base-dialog-card__content {
      padding: 24px 32px;
    }

    .base-dialog-card__footer-actions {
      display: flex;
      align-items: center;
    }

    .base-dialog-card__btn-cancel {
      margin-right: auto;
    }

    .base-dialog-card__btn-confirm {
    }

    &--vertical-buttons {
      .base-dialog-card__footer-actions {
        display: flex;
        flex-wrap: wrap;
      }

      .base-dialog-card__btn-cancel {
        display: flex;
        flex-basis: 100%;
        order: 1;
        margin-top: 12px;
        justify-items: center;

        .ui-btn {
          margin-right: auto;
          margin-left: auto;
        }
      }

      .base-dialog-card__btn-confirm {
        flex-basis: 100%;
        order: 0;
      }
    }
  }
</style>
