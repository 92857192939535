<template lang="pug">
v-sheet.asset-biddings-lot.px-4.py-6(outlined)
  .d-flex.align-center.justify-space-between.mb-1(@click="showDialog = true")
    .t-h3-mobile Лот №{{ lot.number }}
    ui-icon(name="chevron-right")
  asset-badge.asset-biddings-lot__badge.mb-4(v-if="lot.status" kind="bidding_lot" no-shadow) {{ lot.status }}

  .t-small.c-gray70.mb-1 Начальная цена
  .d-flex.align-center.justify-space-between
    .t-caption-normal-bold.c-gray80 {{ lot.start_price }}
    a.link.link--with-icon.c-primary60(v-if="lot.url" :href="lot.url" target="_blank") ЭТП

  v-dialog(
    v-model="showDialog"
    content-class="lot-details-dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    @keydown.esc="showDialog = false"
  )
    v-toolbar.sh-xsmall.lot-details-dialog__toolbar(height=48)
      v-spacer
      v-toolbar-title.t-h3-mobile Лот №{{ lot.number }}
      v-spacer
      ui-btn(plain width=32 @click="showDialog = false")
        ui-icon(name="close")

    .lot-details-dialog__content
      .t-h4-mobile.mt-6 Лот №{{ lot.number }}
      .t-small.c-gra70.mt-4.mb-1 Начальная цена
      .t-caption-normal-bold.c-gray80 {{ lot.start_price }}

      ui-btn.px-0.c-primary60.mt-4(text :href="lot.url" target="_blank")
        | Посмотреть на ЭТП
        ui-icon.c-gray70(name="open-window" right)

      v-divider.mt-4.mb-8
      .t-small.c-gray70.mb-1 Номер дела
      .t-caption-normal.gray80.mb-6 {{ lot.number }}

      .t-small.c-gray70.mb-1 Статус торгов
      .t-caption-normal.gray80.mb-6 {{ lot.status }}

      .t-small.c-gray70.mb-1 Вид торгов
      .t-caption-normal.gray80.mb-6 {{ lot.kind }}

      .t-small.c-gray70.mb-1 Период приема заявок на участие в торгах
      .t-caption-normal.gray80 {{ lot.start_date | dateFull }} — {{ lot.end_date | dateFull }}

      v-divider.mt-8
      ui-expansion-panels.asset-biddings-lot__panel.my-3(v-if="lot.periods && lot.periods.length")
        ui-expansion-panel
          template(#header) 
            .t-caption-normal-bold График снижения цены
          table.asset-biddings-lot-table.mt-4(style="width: 100%")
            tr.asset-biddings-lot-table__header
              td №
              td Начало/окончание
              td.pl-3 Цена
            tr(v-for="(period, index) in lot.periods")
              td.t-small.c-gray70 {{ index + 1 }}
              td {{ period.start_date | datetime }} <br> {{ period.end_date | datetime }}
              td.pl-3 {{ period.price }}
      v-divider
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import AssetBadge from '@/components/asset/AssetBadge.vue';
  import UiExpansionPanel from '@/uikit/components/UiExpansionPanel';
  import UiExpansionPanels from '@/uikit/components/UiExpansionPanels';

  export default {
    name: 'asset-biddings-lot',
    components: { UiBtn, UiIcon, AssetBadge, UiExpansionPanel, UiExpansionPanels },

    props: {
      lot: { type: Object, default: () => {} },
    },

    data() {
      return {
        showDialog: false,
      };
    },
  };
</script>

<style lang="scss">
  .asset-biddings-lot {
    border-radius: 8px !important;

    &__badge {
      width: fit-content;
      border: 1px solid c('gray20', 'light');
    }
  }

  table.asset-biddings-lot-table {
    width: 100%;
    @include text-style('caption-normal', 'light');
    color: c('text', 'light');

    td {
      vertical-align: top;
      padding-bottom: 12px;
    }
  }

  tr.asset-biddings-lot-table__header {
    @include text-style('small', 'light');
    color: c('gray70', 'light');
  }

  .lot-details-dialog {
    background-color: c('white', 'light');

    .lot-details-dialog__toolbar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 48px;
      background-color: c('white', 'light');
      z-index: 10;
    }

    .lot-details-dialog__content {
      padding: 48px 24px 24px 24px;
    }

    .lot-details-dialog__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 12px 16px;
      background-color: c('white', 'light');
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
    }
  }
</style>
