<template lang="pug">
.page-asset
  template(v-if="loading")
    .page-asset__header.sh-xsmall
    .page-asset__photo
      ui-skeleton(type="rect" darker height=280)
    .page-asset__content.content
      .card-sheet.mb-4
        .content-info.d-flex.align-center.mb-6
          ui-skeleton.text--xsmall(type="text" width=90)
          ui-skeleton.text--xsmall.ml-4(type="text" width=90)

        // заголовок
        .t-h1-mobile.mb-4
          ui-skeleton.text--h1(type="text" width=150)
          ui-skeleton.text--h1.ml-2(type="text" width=90)
        .mb-1
          ui-skeleton.text--small(type="text" width=90)
        .mb-4
          ui-skeleton.text--small(type="text" width=90)

        ui-skeleton(type="text" width=60)

        ui-skeleton.py-3.pl-4.mt-8.ml-n4.mr-n4(type="rect" height=44)
          ui-skeleton(type="text" darker width=120)
        .mt-6.mb-2
          ui-skeleton.mb-1(type="text" width=90)
          ui-skeleton(
            type="rect"
            darker
            width=120
            height=28
          )

  template(v-if="!loading && asset")
    .page-asset__header.sh-xsmall
      ui-icon.c-gray70(v-if="!$route.query.no_return" name="arrow-outline2-left" @click="goBack")
      v-spacer
      ui-btn.mr-2(text width=32 @click="copyLink")
        ui-icon.c-gray70.mr-3(name="share")
      asset-favorite-button(
        v-if="canToggleFavorite"
        v-model="asset.favorite"
        no-text
        overlay
        preset="transparent"
        :asset-id="asset.short_id"
        :authorized-to-toggle="asset.politics.authorized_to_toggle_favorite"
        width=32
      )

    .page-asset__photo(v-if="asset.photos && asset.photos.length")
      .page-asset__video(v-if="asset.video")
        ui-icon(name="video--colored" size=20)

      asset-photo(
        v-model="currentPhoto"
        :photos="asset.photos"
        height=280
        @click="fullscreenView = true"
      )

      .page-asset__badges
        asset-badge.page-asset__badges_rights-purchase.mb-2(
          v-if="badgeRightsPurchase"
          :kind="badgeRightsPurchase.kind"
        ) {{ badgeRightsPurchase.name }}

        .d-flex
          asset-id.mr-1(@notify:error="notifyError" @notify:success="notify") {{ asset.short_id }}

          template(v-if="mainBadges")
            asset-badge.mr-1(
              v-for="(badge, index) in mainBadges"
              :key="'main-badge-' + asset.short_id + '-' + index"
              :kind="badge.kind"
            ) {{ badge.name }}

    .page-asset__content.content
      .card-sheet.mb-4
        .content-info.d-flex.align-center.c-black70.mb-5
          ui-icon(name="time-s" left size=16)
          .t-caption-xsmall {{ asset.created_at | dateFullest }}

          ui-icon.ml-4(name="eye-on" left size=16)
          .t-caption-xsmall {{ asset.views_count }} просмотров

        .t-h1-mobile.c-gray100.mb-4 {{ asset.title }}

        asset-address.c-text {{ asset.address }}
        asset-category.c-text.mt-2 {{ asset.kind_name }}

        .mt-4.d-flex.align-center.justify-space-between
          // продавец
          ui-badge(
            color="grey"
            no-shadow
            outline
            style="max-width: 70%"
          )
            .c-gray100 {{ asset.seller }}

          // статус залога
          .asset-card__pledge-status.d-flex.align-center(v-if="asset.pledge_status === 'full'")
            ui-icon.c-gray70.mr-1(name="lock" size=16)
            .t-caption-small.c-gray80 {{ asset.pledge_status_text }}

        // Карточка стоимости
        asset-top-badge.mt-8(:type="asset.sale_type")

        // Гос имущество
        .d-flex.align-center.mt-3(v-if="asset.government")
          ui-icon(name="eagle-symbol--colored" size=28)
          .t-caption-small.c-blue60.ml-3 Продажа государственного и&nbsp;муниципального&nbsp;имущества

        //есть активный или завершенный аукцион - выводим его
        template(v-if="asset.sale_type === 'trade_default' || asset.sale_type === 'trade_dutch'")
          //- asset-trade-card(:trade-id="asset.trade_id")
          asset-trade-card.mt-6(
            v-model="trade"
            :trade-id="asset.trade_id"
            @click:history="showTradeHistory"
            @notify:error="notifyError"
            @notify:info="notify"
            @notify:success="notify"
          )

        // есть стадия банкротства / испол. производства - выводим торги
        template(
          v-else-if="asset.sale_type === 'bidding_bankruptcy' || asset.sale_type === 'bidding_commerce' || asset.sale_type === 'bidding_enforcement'"
        )
          asset-bidding-info(
            :bidding-end-date="asset.bidding_end_date"
            :bidding-start-date="asset.bidding_start_date"
            :discount-percentage="asset.discount_percentage"
            :price="asset.price"
            :start-price="asset.start_price"
            :wait-bidding="asset.wait_bidding"
          )

        // выводим прямую продажу
        template(v-else)
          asset-price-info(:price="asset.price" :rent="asset.rent")

        // кнопки Сервисов
        asset-service-buttons.mt-6(
          v-if="asset.available_services"
          :asset-id="asset.short_id"
          :services="asset.available_services"
          @click:all="openServices"
          @click:service="openService"
        )

        .separator-line.mt-5(
          v-if="applicationsCount || prevTrades.total > 0 || asset.politics.has_efrsb_lots || asset.politics.has_biddings"
        )

        // плашка под торгами и аукционами
        asset-trade-and-bidding-info(
          :applications-count="applicationsCount"
          :can-view-marketplace="asset.politics.can_view_marketplace"
          :has-marketplace="asset.politics.has_marketplace"
          :has-previous-trades="prevTrades.total > 0"
          :marketplace="asset.marketplace"
          :trial-stage="asset.trial_stage"
          @openPreviousAuctions="openPreviousAuctions"
          @openPreviousBiddings="openPreviousBiddings"
          @openTradeHistory="showTradeHistory"
        )

      .card-sheet.mb-4(v-if="asset.business_status === 'active'")
        asset-primary-fields(
          :business-revenue="asset.business_revenue"
          :business-revenue-text="asset.business_revenue_formatted"
        )

      // Характеристики
      .card-sheet.mb-4(v-if="asset.specific_fields && asset.specific_fields.length")
        .t-h2-mobile.c-gray100.mb-4 Характеристики
        .mb-4(
          v-for="(field, index) in asset.specific_fields"
          :key="field.field"
          v-show="showSpecifications ? showSpecifications : index < 5"
        )
          .t-caption-small.c-gray70.mb-1 {{ field.name }}
          .t-caption-normal.c-text {{ field.value }}

        .t-button-middle.c-primary.d-flex.align-center(
          v-if="asset.specific_fields.length >= 6"
          @click="showSpecifications = !showSpecifications"
        )
          | {{ showSpecifications ? "Скрыть" : "Все характеристики" }}
          ui-icon.rotate-90(
            size=24
            :name="showSpecifications ? 'arrow-outline-left' : 'arrow-outline-right'"
          )

      // Описание
      .card-sheet.mb-4
        .t-h2-mobile.c-gray100.mb-4 Описание
        .t-normal.c-text(
          :class="!showDescription && longDescription ? 'text-line-clamp' : ''"
          v-html="asset.description"
        )
        .description-btn.t-button-middle.c-primary.mt-3.d-flex.align-center(
          v-if="asset.description.length > 100"
          @click="showDescription = !showDescription"
        )
          | {{ showDescription ? "Скрыть" : "Полное описание" }}
          ui-icon.rotate-90(
            size=24
            :name="showDescription ? 'arrow-outline-left' : 'arrow-outline-right'"
          )

      // Блок "Собственник" и "Заёмщик"
      .card-sheet.mb-4
        // Собственник
        template.mb-4(v-if="asset.proprietors && asset.proprietors.length")
          .t-h2-mobile.c-gray100.mb-4 Собственник
          asset-proprietors-info(:items="asset.proprietors")

        .separator-line.my-6(
          v-if="asset.proprietors && asset.proprietors.length && asset.borrowers && asset.borrowers.length"
        )

        // Заёмщик
        template.mb-4(v-if="asset.borrowers && asset.borrowers.length")
          .t-h2-mobile.c-gray100.mb-4 Заёмщик
          asset-borrowers-info(:items="asset.borrowers")

        .page-asset__auth-block.mb-6(v-if="!asset.proprietors && !asset.borrowers")
          ui-btn.c-primary(text :to="$path.signIn()") Войдите,&nbsp;
          | чтобы посмотреть информацию о собственниках/заёмщиках

        // Связанные активы
        //- template(v-if="asset.related_assets && asset.related_assets.length")
        //-   .separator-line.mt-3
        //-   ui-btn.mb-n5.px-0(
        //-     large
        //-     text
        //-     width="100%"
        //-     @click="openRelatedAssets"
        //-   )
        //-     .t-caption-normal.c-primary.text-left.flex-grow-1.mr-1 Связанные активы
        //-     .t-caption-normal.c-gray70.mr-1 {{ asset.related_assets.length }}
        //-     ui-icon.c-gray80(name="arrow-outline-right")

      // Адрес
      .card-sheet.mb-4.pb-8
        .t-h2-mobile.c-gray100.mb-3 Адрес
        asset-address.mb-3 {{ asset.address }}
        .page-asset__address-map(v-if="asset.politics.can_view_address")
          asset-map(v-if="asset.address" :address="asset.address")

        .page-asset__auth-block.page-asset__auth-block_map(v-else)
          ui-btn.c-primary(text :to="$path.signIn()") Войдите,&nbsp;
          | чтобы посмотреть точный адрес

      // Документы
      .card-sheet.mb-4(v-if="asset.specific_documents && asset.specific_documents.length")
        .t-h2-mobile.c-gray100.mb-4 Документы
        template(v-if="asset.politics.can_view_documents")
          ui-file-info-item(
            v-for="(document, index) in asset.specific_documents"
            :key="index"
            :ext="document.extension"
            :href="document.document_url"
            :name="document.name"
            :size="document.size"
          )

        template(v-else)
          .page-asset__auth-block.mb-6
            ui-btn.c-primary(text :to="$path.signIn()") Войдите,&nbsp;
            | чтобы посмотреть информацию о документах

      // Контакты
      .card-sheet.mb-4.page-asset__contacts.mb-8
        asset-chat-button.mb-4(
          v-if="asset.communication_method !== 'phone'"
          large
          text="Чат"
          width="100%"
          @click="openConversation"
        )
        asset-phone-button(
          v-if="asset.communication_method !== 'message'"
          large
          :asset-id="asset.short_id"
          :can-view-phone="asset.politics.can_view_phone"
          width="100%"
          @error:auth="requireAuth"
          @notify:error="notifyError"
          @notify:success="notify"
        )

        asset-contact-info.mt-8(
          :contact="asset.contact"
          :seller-assets-count="asset.seller_assets_count"
          :seller-name="asset.seller"
        )

        asset-recommended(v-if="asset.recommended_assets" :assets="asset.recommended_assets")

        .separator-line.my-6(v-if="asset.available_services.length")

    //- связанные активы
    //- asset-related-dialog(ref="assetRelatedDialog" :assets="asset.related_assets")

    // история ставок
    asset-trade-history-dialog(
      v-if="trade && trade.applications"
      ref="assetTradeHistoryDialog"
      :trades="trade.applications"
    )

    // полноэкранный просмотр фото
    v-overlay(:value="fullscreenView" opacity="1")
      asset-photo-slider(
        v-model="currentPhoto"
        :photos="asset.photos"
        @close="fullscreenView = false"
      )
        template(#footer)
          .d-flex
            asset-chat-button.flex-grow-1(large text="Задать вопрос" @click="openConversation")
              ui-icon(name="message-question")
            asset-phone-button.ml-2(
              large
              no-text
              :asset-id="asset.short_id"
              :can-view-phone="asset.politics.can_view_phone"
              width=48
              @error:auth="requireAuth"
              @notify:error="notifyError"
              @notify:success="notify"
            )
    asset-trade-previous-dialog(
      v-if="trade"
      ref="assetTradePreviousDialog"
      :trades="prevTrades.trades"
    )
    asset-biddings-previous-dialog(
      v-if="biddings"
      ref="assetBiddingsPreviousDialog"
      :biddings="biddings"
    )

    service-info-dialog(ref="serviceDialog" @click:all="openServices")
    asset-services-dialog(
      v-if="asset.available_services"
      ref="assetServicesDialog"
      :assetId="assetId"
      :services="asset.available_services"
      @click:service="openService"
    )
</template>

<script>
  import { UiIcon, UiBtn, UiFileInfoItem, UiLoader, UiBadge, UiSkeleton } from '@/uikit';
  import { AssetPhoto, AssetAddress, AssetCategory } from '@/components/asset';
  import AssetServiceButtons from '@/components/asset/components/AssetServiceButtons.vue';
  import AssetBadge from '@/components/asset/AssetBadge.vue';
  import AssetBiddingInfo from '@/components/asset/components/AssetBiddingInfo.vue';
  import AssetBiddingsLot from '@/components/asset/AssetBiddingsLot.vue';
  import AssetBiddingsPreviousDialog from '@/components/asset/components/AssetBiddingsPreviousDialog.vue';
  import AssetBorrowersInfo from '@/components/asset/AssetBorrowersInfo.vue';
  import AssetChatButton from '@/components/asset/components/AssetChatButton.vue';
  import AssetContactInfo from '@/components/asset/components/AssetContactInfo.vue';
  import AssetRecommended from '@/components/asset/components/AssetRecommended.vue';
  import AssetFavoriteButton from '@/components/asset/AssetFavoriteButton.vue';
  import AssetId from '@/components/asset/AssetId.vue';
  import AssetMap from '@/components/asset/AssetMap.vue';
  import AssetPhoneButton from '@/components/asset/components/AssetPhoneButton.vue';
  import AssetPhotoSlider from '@/components/asset/AssetPhotoSlider.vue';
  import AssetPriceInfo from '@/components/asset/components/AssetPriceInfo.vue';
  import AssetPrimaryFields from '@/components/asset/components/AssetPrimaryFields.vue';
  import AssetProprietorsInfo from '@/components/asset/AssetProprietorsInfo.vue';
  import AssetTopBadge from '@/components/asset/AssetTopBadge.vue';
  import AssetTradeAndBiddingInfo from '@/components/asset/components/AssetTradeAndBiddingInfo.vue';
  import AssetTradeCard from '@/components/asset/components/AssetTradeCard.vue';
  import AssetTradeHistoryDialog from '@/components/asset/components/AssetTradeHistoryDialog.vue';
  import AssetTradePreviousDialog from '@/components/asset/components/AssetTradePreviousDialog.vue';
  import ServiceInfoDialog from '@/components/asset/ServiceInfoDialog.vue';
  import AssetServicesDialog from '@/components/asset/AssetServicesDialog.vue';

  import { handleError } from '@/libs/handle-error';
  import { service as API } from '@/services/assets';
  import { copyTextToClipboard } from '@/uikit/util/copy-clipboard';

  import { createLogger } from '@/uikit/util/logger';
  import AssetRelatedDialog from '@/components/asset/AssetRelatedDialog.vue';
  const logger = createLogger('PageAsset', 'blue');

  export default {
    name: 'page-asset',
    components: {
      AssetRelatedDialog,
      UiIcon,
      UiBtn,
      UiFileInfoItem,
      UiLoader,
      UiBadge,
      UiSkeleton,
      AssetPhoto,
      AssetId,
      AssetBadge,
      AssetAddress,
      AssetMap,
      AssetCategory,
      AssetContactInfo,
      AssetChatButton,
      AssetPhoneButton,
      AssetBiddingsLot,
      AssetProprietorsInfo,
      AssetBorrowersInfo,
      AssetPhotoSlider,
      AssetTradeCard,
      AssetFavoriteButton,
      AssetTradePreviousDialog,
      AssetBiddingsPreviousDialog,
      AssetPrimaryFields,
      AssetTopBadge,
      AssetPriceInfo,
      AssetBiddingInfo,
      AssetServiceButtons,
      AssetTradeAndBiddingInfo,
      AssetTradeHistoryDialog,
      ServiceInfoDialog,
      AssetServicesDialog,
      AssetRecommended,
    },
    inject: ['requireAuth'],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // если вернулись из диалогов то стираем путь актива, который сохраняли для возврата
        if (['pageInteractions', 'pageInteractionDialog'].indexOf(from.name) > -1) {
          vm.$store.commit('interactions/setValue', { field: 'assetPath', value: '' });
        }
      });
    },

    props: {
      assetId: { type: String, required: true },
    },

    data() {
      return {
        showSpecifications: false,
        showDescription: false,
        loading: true,
        loadingBiddings: true,
        infoTab: 0,
        proprietorItem: 0,
        asset: null,
        fullscreenView: false,
        trade: null,
        biddings: null,
        currentPhoto: 0,
        prevTrades: {
          trades: [],
        },
      };
    },

    computed: {
      currentUser() {
        return this.$store.getters['currentUser'];
      },

      canToggleFavorite() {
        return this.asset.politics.can_toggle_favorite;
      },

      longDescription() {
        return this.asset.description.length > 10;
      },

      badgeRightsPurchase() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['rights_purchase'].includes(badge.kind))[0]
          : undefined;
      },

      mainBadges() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['my', 'new'].includes(badge.kind))
          : undefined;
      },

      // otherBadges() {
      //   return this.asset.badges
      //     ? this.asset.badges.filter((badge) =>
      //         ['trade', 'trade_finished', 'biddings'].includes(badge.kind)
      //       )
      //     : undefined;
      // },

      biddingsInfo() {
        return this.biddings ? this.biddings.biddings : undefined;
      },

      applicationsCount() {
        return this.trade ? this.trade.applications_count : undefined;
      },

      sellerSber() {
        return this.asset.seller === 'ПАО Сбербанк';
      },
    },

    watch: {
      asset(asset) {
        if (asset && (asset.politics.can_view_biddings || true)) {
          this.fetchAssetBiddings();
        }
        if (asset && (asset.politics.can_view_previous_trades || true)) {
          this.fetchPreviousTrades();
        }
      },
    },

    mounted() {
      this.fetchAsset();
    },

    methods: {
      goBack() {
        // this.$store.dispatch('catalog/setPreinitialized', true);
        // this.$router.go(-1);
        if (!this.$route.query.no_return) this.$router.push(this.$path.catalog());
      },

      notify(msg) {
        this.$notify.info(msg);
      },

      notifyError(msg) {
        this.$notify.error(msg);
      },

      async fetchAsset() {
        try {
          this.loading = true;
          this.asset = await API.getAsset(this.assetId);
        } catch (error) {
          handleError(error, logger);
          this.$notify.error('Не удалось загрузить актив');
        } finally {
          this.loading = false;
        }
      },

      async fetchAssetBiddings() {
        try {
          this.loadingBiddings = true;
          this.biddings = await API.getAssetBiddings(this.assetId);
        } catch (error) {
          handleError(error, logger);
          this.$notify.error('Не удалось загрузить информацию о торгах');
        } finally {
          this.loadingBiddings = false;
        }
      },

      async fetchPreviousTrades() {
        try {
          this.prevTrades = await API.getPreviousTrades(this.assetId);
        } catch (error) {
          handleError(error, logger);
        }
      },

      async openPreviousAuctions() {
        this.$refs.assetTradePreviousDialog.open();
      },

      async openPreviousBiddings() {
        if (!this.currentUser) {
          this.$notify.info(
            'Для просмотра информации о торгах необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        await this.$refs.assetBiddingsPreviousDialog.open();
      },

      async copyLink() {
        try {
          const link = window.location.href;
          await copyTextToClipboard(link);
          this.$notify.success('Ссылка скопирована в буфер обмена', 2000);
        } catch (error) {
          this.$notify.error('Ошибка копирования в буфер обмена', 2000);
        }
      },

      async showTradeHistory() {
        if (!this.currentUser) {
          this.$notify.info(
            'Для просмотра истории ставок аукциона необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        await this.$refs.assetTradeHistoryDialog.open();
      },

      openConversation() {
        if (this.asset.politics.can_ask) {
          this.$router.push(this.$path.interactionDialog(this.assetId));
        } else {
          this.requireAuth('Чтобы задать вопрос необходимо войти или зарегистрироваться.');
        }
      },

      openRelatedAssets() {
        this.$refs.assetRelatedDialog.open();
      },

      openService(service) {
        logger.log(`openService ${service.name} asset:${this.asset.short_id}`);
        this.$refs.serviceDialog.open(service, this.asset);
      },

      openServices() {
        logger.log('openServices');
        this.$refs.assetServicesDialog.open();
      },
    },
  };
</script>

<style lang="scss">
  .page-asset {
    background-color: c('gray5', 'light');

    .page-asset__loader {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .page-asset__header {
      display: flex;
      height: 48px;
      align-items: center;
      padding: 0 16px;
      background-color: c('white', 'light');
    }

    .theme--light.v-btn:hover::before {
      opacity: 0;
    }

    .rotate-90 {
      transform: rotate(90deg);
    }

    .separator-line {
      height: 2px;
      background-color: c('gray5', 'light');
      margin: 0 -16px;
    }

    .position-right {
      position: absolute;
      right: -8px;
    }

    &__video {
      position: absolute;
      bottom: 42px;
      right: 18px;
      z-index: 2;
      width: 20px;
      height: 20px;
    }

    &__address-map {
      margin: 0 -16px;
      height: 160px;
      filter: drop-shadow(0 3px 8px rgba(0, 0, 0, 0.08));
    }

    .page-asset__photo {
      position: relative;
    }

    .page-asset__badges {
      position: absolute;
      bottom: 16px;
      left: 16px;

      &_rights-purchase {
        border: 1px solid c('gray20', 'light');
      }
    }

    .page-asset__asset-id {
      margin-right: 4px;
    }

    .page-asset__contacts {
      .v-btn.asset-phone-button {
        color: c('gray100', 'light') !important;

        .icon {
          background-color: c('primary60', 'light') !important;
        }

        &--done {
          color: white !important;

          .icon {
            background-color: white !important;
          }
        }
      }
    }

    &__content {
      .asset-tabs .v-slide-group__prev--disabled {
        display: none !important;
      }
    }

    .possible-deals__item {
      display: flex;

      &::before {
        @include svg-bg-mask-recolored('check-round-full', c('primary', 'light'), light);
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }

    .text-line-clamp {
      max-height: 100px;
      overflow: hidden;
    }

    &__auth-block {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('~@/assets/auth-reg.svg') no-repeat left center / cover;
      height: 170px;
      padding: 0 70px;
      flex-direction: column;
      text-align: center;

      &_map {
        height: 300px;
        background: url('~@/assets/auth-reg-map.svg') no-repeat center center / cover;
      }
    }

    &__prev-auction-btn.theme--light.btn-secondary.v-btn {
      border: 1px solid c('black40', 'light') !important;
      color: c('black80', 'light') !important;
      margin-top: 16px;

      .icon-arrow-right {
        background-color: c('black80', 'light') !important;
      }
    }
  }
</style>
