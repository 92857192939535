<script>
  import { logger } from '@/uikit/util/logger';
  export default {
    name: 'viewport-size-observer',

    props: {
      delay: { type: Number, default: 400 },
    },

    data() {
      return {
        rects: {
          window: {},
          document: {},
          body: {},
        },
      };
    },

    mounted() {
      this.updateRects();
      this.$emit('initialized', this.rects);
      window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },

    methods: {
      onResize() {
        this.$emit('resize:start');
        logger.log('Window resizing:start');

        if (this.timerId) clearTimeout(this.timerId);
        this.timerId = setTimeout(() => this.onResizeEnd(), this.delay);
      },

      onResizeEnd() {
        this.updateRects();

        logger.log('Window resizing:end', this.rects);
        this.$emit('resize:end', this.rects);
      },

      updateRects() {
        this.rects = {
          window: { width: window.innerWidth, height: window.innerHeight },
          document: {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
          },
          body: {
            width: document.body.clientWidth,
            height: document.body.clientHeight,
          },
        };
      },
    },

    render() {
      return null;
    },
  };
</script>
