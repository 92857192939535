<template lang="pug">
v-item-group.slider-dots(v-model="current" mandatory)
  v-item.slider-dots__dots(
    v-for="n in total"
    :key="`slider-dots-${n}`"
    v-slot="{ active, toggle }"
  )
    .slider-dots__item(:class="{ active: n === current + 1 }")
</template>

<script>
  export default {
    name: 'slider-dots',
    props: {
      value: { type: Number, default: 0 }, // текущий слайд
      total: { type: Number, default: 0 },
    },

    computed: {
      current: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },
  };
</script>

<style lang="scss">
  .slider-dots {
    display: flex;
    justify-content: center;
    align-items: center;

    &__dots {
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }

    &__item {
      margin-right: 4px;
      height: 6px;
      width: 6px;
      border-radius: 6px;
      background: c('black80', 'light');

      &:last-child {
        margin-right: 0;
      }
    }

    .active {
      background: c('primary', 'light');
      width: 16px;
    }
  }
</style>
