<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .service-info-header
    .service-info-header__content
      .t-h1-mobile.c-gray100.pb-2 Агент на торгах
      .t-subtitle.c-gray100 Агент по торгам возьмет на себя весь процесс от подачи заявки до участия в&nbsp;торгах
    .service-info-header__icon-bg
      img.service-info-header__icon(src="~@/assets/96/agent.svg")
    .service-info-header__price-info
      .t-caption-middle-bold.c-gray100.mr-4 {{ service.price }}
      .t-small Стоимость<br>агентских услуг

  .px-4
    .t-h3-mobile.c-gray100.mt-10.mb-6 Преимущества привлечения агента
    .d-flex.align-start.justify-space-between
      .service-info-advantage-card.mb-4
        .service-info-advantage-card__icon
          ui-icon.c-primary60(name="time-s")
        .t-normal Заключение договора через сайт за&nbsp;2&nbsp;минуты
      .service-info-advantage-card.mb-4
        .service-info-advantage-card__icon
          ui-icon.c-primary60(name="rouble-cycle")
        .t-normal Оплата услуги и&nbsp;задатков в&nbsp;2&nbsp;клика
      .service-info-advantage-card
        .service-info-advantage-card__icon
          ui-icon.c-primary60(name="asset-search")
        .t-normal Удобный контроль всех этапов

    .t-h3-mobile.c-gray100.mt-10.mb-3 Агент по торгам возьмет на себя весь процесс от подачи заявки до участия в торгах
    ul.bullet-list.t-middle.mb-4.pl-0
      li.bullet-list__item.bullet-list__item--checked Агент проверит требования, документы, сформирует и подаст заявку, примет участие в торгах от вашего имени
      li.bullet-list__item.bullet-list__item--checked.mt-1 Вам не нужно получать электронную цифровую подпись
      li.bullet-list__item.bullet-list__item--checked.mt-1 Специалисты с гарантией допуска к участию, аккредитованные на основных торговых площадках
      li.bullet-list__item.bullet-list__item--checked.mt-1 Агент проконсультирует вас о дальнейших действиях после завершения торгов. Взаимодействие происходит дистанционно

    .t-h3-mobile.c-gray100.mt-12.mb-6 Порядок действий
    .service-info-order-card.d-flex
      ui-icon(name="file-document-bg-checked" originalColor size=65)
      .t-normal.ml-2.mr-16.mt-1 Подать заявку на Portal DA
      .t-huge-number.c-gray10(style="right: 28px") 1
    .service-info-order-card.d-flex
      ui-icon(name="currency-rub-bg" originalColor size=56)
      .t-normal.ml-4.mr-16.mt-1 Оплатить услуги агента – {{ service.price }}
      .t-huge-number.c-gray10 2
    .service-info-order-card.d-flex
      ui-icon(name="files-2-bg" originalColor size=56)
      .t-normal.ml-4.mr-16.mt-1 Направить агенту необходимые документы для участия в торгах
      .t-huge-number.c-gray10 3
    .service-info-order-card.d-flex
      ui-icon(name="case-check-bg" originalColor size=56)
      .t-normal.ml-4.mr-16.mt-1 В случае победы на торгах, заключить договор купли-продажи имущества
      .t-huge-number.c-gray10 4
    
    .t-h3-mobile.c-gray100.mt-10.mb-3 Заинтересовал данный лот?<br>Для самостоятельной покупки вам необходимо:
    ol.numbered-list.t-middle.ml-4.pl-0
      li.numbered-list__item.mb-1 Собрать документы в соответствии с требованиями конкретной торговой площадки
      li.numbered-list__item.mb-1 Купить электронную цифровую подпись (стоимость до&nbsp;17&nbsp;000&nbsp;руб.)
      li.numbered-list__item.mb-1 Пройти аккредитацию на электронной торговой площадке (в случае ошибки в документах или шагах – отказ в аккредитации)

    .t-middle.mb-6.mt-3 Непростая задача? Требует много времени?<br>Есть решение!

    .t-h3-mobile.c-gray100.mt-12.mb-2 Услугу предоставляет ООО ПКО «АБК»
    .t-caption-normal.c-gray70 дочерняя компания ПАО Сбербанк
    img.mt-6(src="~@/assets/96/activebc-logo.svg")

  template(#footer)
    ui-btn.mb-2(
      large
      primary
      width="100%"
      @click="apply"
    ) Заполнить заявку

    asset-contact-button-sberlot(
      large
      :asset-id="assetId"
      :can-ask="canAsk"
      :seller-sber="sellerSber"
      @click="openConversation"
    ) Получить консультацию
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import AssetContactButtonSberlot from '@/components/asset/components/AssetContactButtonSberlot.vue';
  import { UiBtn, UiIcon } from '@/uikit/';
  import { logger, service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, AssetContactButtonSberlot },
    inject: ['requireAuth', 'openDesktop'],
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },

    methods: {
      apply() {
        if (this.canAsk) {
          seoEventService.safeTriggerEvent(this.assetId, this.service.name, [
            'application',
            'web_mobile',
          ]);
          this.openDesktop({
            path: this.service.application_url,
            text: 'Открыть эту страницу в полной версии сайта?',
          });
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },

      openConversation() {
        if (this.canAsk) {
          this.$router.push(this.$path.interactionDialog(this.assetId));
        } else {
          this.requireAuth('Чтобы задать вопрос необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>

<style lang="scss">
  .service-info-header {
    padding: 48px 32px 32px 16px;
    background-color: rgba(246, 247, 251, 1);
    border-radius: 0 0 16px 16px;
    position: relative;
    overflow: hidden;

    .t-small {
      font-size: 12px;
      line-height: 12px;
    }
    .t-caption-middle-bold {
      font-size: 24px;
    }
  }

  .service-info-header__content {
    padding-right: 84px;
  }

  .service-info-header__icon-bg {
    position: absolute;
    display: flex;
    right: -35px;
    top: -40px;
    border-radius: 50%;
    background: linear-gradient(222deg, #baffbf 1%, rgba(255, 255, 255, 0) 70%);
    width: 206px;
    height: 206px;
    align-items: center;
    justify-content: center;
  }

  .service-info-header__icon {
    width: 96px;
    height: 96px;
  }

  .service-info-header__price-info {
    display: flex;
    align-items: center;
    padding-top: 16px;
    margin-top: 20px;
    border-top: 1px solid rgba(224, 225, 230, 1);
  }

  .service-info-advantage-card {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 28%;

    &__icon {
      border-radius: 50%;
      padding: 16px;
      box-shadow: 0px 2px 16px 0px rgba(73, 82, 116, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }
  }
</style>
