<template lang="pug">
.asset-proprietors-info
  v-window.asset-proprietors-info__items.mb-4(v-model="currentItem")
    v-window-item(v-for="(proprietor, index) in items" :key="index")
      .asset-proprietor-card
        .asset-proprietor-card__title.t-caption-small.c-gray70.mb-1
          span {{ proprietor.kind }}
          span(v-if="proprietor.inn") &nbsp;—&nbsp;ИНН {{ proprietor.inn }}
        .asset-proprietor-card__text--no-icon.t-caption-normal.c-text {{ proprietor.name }}

        template(
          v-if="proprietor.trial_stage !== 'Отсутствует' && proprietor.trial_stage !== null"
        )
          .asset-proprietor-card__title.t-caption-small.c-gray70.mb-1.mt-5 Стадия взыскания / банкротства:
          .asset-proprietor-card__text.t-caption-normal.c-text {{ proprietor.trial_stage }}

  slider-dots(v-if="items.length > 1" v-model="currentItem" :total="items.length")
</template>

<script>
  import SliderDots from '@/components/SliderDots.vue';

  export default {
    name: 'asset-proprietors-info',
    components: { SliderDots },
    props: {
      items: { type: Array, default: () => [] },
    },

    data() {
      return {
        currentItem: 0,
      };
    },
  };
</script>

<style lang="scss">
  .asset-proprietors-info {
  }

  .asset-proprietors-info__items {
    padding: 24px 16px;
    border-radius: 4px;
    background-color: c('white', 'light');
    border: 1px solid c('black10', 'light');
    box-shadow: shadow('xsmall', 'light');
  }

  .asset-proprietor-card {
    &__text {
      display: flex;

      &::before {
        @include svg-bg-mask-recolored('check-round-full', c('primary', 'light'), light);
        content: '';
        display: block;
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin-right: 4px;
        margin-top: 2px;
      }
    }
  }
</style>
