<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :asset-long-id="asset.id"
  :service-name="service.name"
)
  .service-desc-header.service-desc-header--auto-credit
    .service-desc-header__content
      .service-desc-header__title.c-gray100.mb-2 Автокредит
      .service-desc-header__description.c-gray100 Приобретите автомобиль мечты
    .service-desc-header__icon
      img(src="~@/assets/96/service-auto_credit.svg")

  .px-4
    .t-h3-mobile.c-gray100.mt-10.mb-5 Преимущества
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="case-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Минимум документов
        .t-middle.c-gray80 Вам понадобится только паспорт и&nbsp;водительское удостоверение
    .d-flex
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="timer-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Досрочное погашение
        .t-middle.c-gray80 ​​​​​​​Можно полностью или&nbsp;частично погасить автомобильный кредит онлайн без&nbsp;комиссий

    .t-h3-mobile.c-gray100.mt-10.mb-6 Порядок действий
    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="file-document")
          .icon-in-circle__overlay-icon
            .icon.icon-check-circle-green--colored
      .service-page-numbered-card__content
        .t-normal.c-gray100 Подайте заявку на&nbsp;автокредит в&nbsp;СберБанк&nbsp;Онлайн и&nbsp;дождитесь одобрения
      .service-page-numbered-card__number.c-gray10 1

    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="case-check")
      .service-page-numbered-card__content
        .t-normal.c-gray100 Подпишите кредитный договор в&nbsp;СберБанк&nbsp;Онлайн, получите деньги на&nbsp;счет и&nbsp;станьте счастливым обладателем автомобиля!
      .service-page-numbered-card__number.c-gray10 2

    .service-page-numbered-card.service-page-numbered-card--small.mb-2
      .service-page-numbered-card__image
        .icon-in-circle.bg-gray5
          ui-icon.c-gray80(name="timer")
      .service-page-numbered-card__content
        .t-normal.c-gray100 У&nbsp;вас будет 55&nbsp;дней для&nbsp;покупки автомобиля ипередачи его в&nbsp;залог банку
      .service-page-numbered-card__number.c-gray10 3

    .text--middle.text--bold.mb-4.mt-10 Условия выдачи кредита:
    ul.lined-list.text--middle.ml-2.mb-6
      li.lined-list__item Вы гражданин Российской Федерации с&nbsp;постоянной регистрацией на&nbsp;территории России
      li.lined-list__item Вам уже исполнился 21 год. На&nbsp;момент окончания срока кредитного договора вы должны быть не&nbsp;старше 73 лет
      li.lined-list__item Первоначальный взнос не&nbsp;требуется

    .t-h3-mobile.c-gray100.mt-12 Кредит предоставляет ПАО СберБанк 
    img.mt-6(src="~@/assets/logos/sber-logo.svg")

  template(#footer)
    ui-btn.ui-append-icon(
      large
      primary
      width="100%"
      @click="click"
      :loading="loading"
    )
      | Оформить автокредит

</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import { UiBtn, UiIcon } from '@/uikit/';
  import { service as seoEventService, logger } from '@/services/seo-events/seo-event-service.js';
  import axios from 'axios';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo },
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    data() {
      return {
        link: '',
        loading: true,
      };
    },

    mounted() {
      this.getAutoCreditLink();
    },

    methods: {
      async getAutoCreditLink() {
        await axios({
          method: 'post',
          url: process.env.VUE_APP_API_HOST + '/api/v4/claims/auto_credit',
          params: { asset_id: this.asset.id },
        })
        .then((response) => {
          if (response?.status === 200) {
            this.link = response.data.link;
          }
        })
        .catch((error) => logger.error(error))
        .finally(() => this.loading = false);
      },

      click() {
        try {
          seoEventService.triggerEvent(this.assetId, 'auto_credit', ['client']);
        } catch (error) {
          logger.error(error);
        }

        window.open(this.link, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .service-desc-header--auto-credit {
    min-height: 62px;
    padding: 48px 32px 32px;
  }
</style>
  