<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .px-4.c-text
    .t-h1-mobile.my-6.c-gray100 Имущество на торгах в банкротстве можно купить в ипотеку
    .t-normal-bold.c-gray100.mb-4 Преимущества
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Быстрое рассмотрение заявки
      li.bullet-list__item.bullet-list__item--checked Минимальный пакет документов

    .t-normal-bold.mb-2.mt-4 Условия выдачи кредита
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Собственное участие – не менее 30%
      li.bullet-list__item.bullet-list__item--checked Срок – до 3-лет
      li.bullet-list__item.bullet-list__item--checked Обеспечение – залог приобретаемого на торгах имущества

    .t-normal-bold.c-gray100.mb-2 Особые условия
    .t-middle.mb-2 Кредит предоставляется юридическому лицу или индивидуальному предпринимателю – резиденту РФ, не имеющему кредитных обязательств или предодобренных лимитов в ПАО Сбербанк.

    .t-normal-bold.c-gray100.mb-2.mt-8 Три шага для получения кредита
    ol.numbered-list.t-middle.ml-2.mb-6
      li.numbered-list__item.mb-1 Заявка на ипотеку
      li.numbered-list__item.mb-1 Победа на торгах
      li.numbered-list__item.mb-1 Оформление сделки

  template(#footer)
    .d-flex
      ui-btn.mr-2(
        large
        primary
        width="85%"
        @click="openCreditApplication"
      ) Заказать услугу

      asset-chat-button(large width="44" @click="$emit('click:chat')")
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import AssetChatButton from '@/components/asset/components/AssetChatButton.vue';
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, AssetChatButton },
    inject: ['requireAuth'],
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },

    methods: {
      openCreditApplication() {
        if (this.canAsk) {
          window.location = this.$path.creditDrpaServiceApplication(this.assetId);
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>
