<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .t-middle.mb-4 Заинтересовал данный лот? Для самостоятельной покупки вам необходимо:
  ol.numbered-list.t-middle.c-text.ml-2
    li.numbered-list__item.mb-1 собрать все необходимые документы;
    li.numbered-list__item.mb-1 получить электронную подпись;
    li.numbered-list__item.mb-1 зарегистрироваться на электронной площадке;
    li.numbered-list__item.mb-1 внести задаток;
    li.numbered-list__item.mb-1 принять участие в торгах.

  .t-middle.c-text.mb-6 Непростая задача? Требует много времени? Есть решение!

  .t-normal-bold.c-gray100.mb-4 Агент по торгам возьмет на себя весь процесс от подачи заявки до участия в торгах.

  ul.bullet-list.mb-4.t-middle.c-text.mb-4
    li.bullet-list__item.bullet-list__item--checked Агент имеет ЭЦП
    li.bullet-list__item.bullet-list__item--checked Агент аккредитован на большинстве торговых площадок
    li.bullet-list__item.bullet-list__item--checked Агент имеет огромный опыт участия в торгах, настоящий профессионал своего дела!

  .t-middle.c-text.mb-4 Сделайте первый шаг к выгодному приобретению прямо сейчас.

  template(#footer)
    .d-flex
      ui-btn.mr-2(
        large
        primary
        width="85%"
        @click="$emit('click:apply')"
      ) Заказать услугу

      asset-chat-button(large width="44" @click="$emit('click:chat')")
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import AssetChatButton from '@/components/asset/components/AssetChatButton.vue';
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, AssetChatButton },
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },
  };
</script>
