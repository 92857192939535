<template lang="pug">
.asset-trade-and-bidding-info(v-if="applicationsCount || hasPreviousTrades || hasMarketplace")
  .d-flex.align-center(
    :class="hasMarketplace ? 'mb-2' : ''"
    v-if="applicationsCount || hasPreviousTrades"
  )
    .d-flex.align-center.mr-6(v-if="applicationsCount")
      ui-icon.mr-2.c-gray70(name="hand" size=24)
      .link.link--primary(@click="$emit('openTradeHistory')") {{ applicationsCountText }}
    .d-flex.align-center(v-if="hasPreviousTrades")
      ui-icon.mr-2.c-gray70(name="history" size=24)
      .link.link--primary(@click="$emit('openPreviousAuctions')") Предыдущие аукционы

  div(:class="{ 'mt-4': applicationsCount || hasPreviousTrades }" v-if="hasMarketplace")
    template(v-if="canViewMarketplace")
      a.link.t-caption-normal-bold.c-gray100(
        v-if="marketplace.url"
        rel="nofollow"
        v-tooltip="{ content: 'Открыть сайт электронной торговой площадки' }"
        :href="marketplace.url"
        target="_blank"
      ) {{ biddingsInfoText }}
      .t-caption-normal-bold(v-else) {{ biddingsInfoText }}

      ui-badge.mt-2.asset-trade-and-bidding-info__badge(v-if="trialStage" no-shadow)
        .c-gray80.t-small {{ trialStage }}
    template(v-else)
      ui-badge.mt-2.asset-trade-and-bidding-info__badge(no-shadow)
        .c-gray80.t-small Войдите, чтобы увидеть информацию о торгах
    .d-flex.align-center.mt-4
      ui-icon.mr-2.c-gray70(name="auction-right" size=24)
      .t-button-middle.c-primary60(@click="$emit('openPreviousBiddings')") Информация о торгах
</template>

<script>
  import { UiBtn, UiIcon, UiBadge } from '@/uikit';
  import { formatNumbers } from '@/uikit/filters/pluralize';

  export default {
    name: 'asset-trade-and-bidding-info',
    components: { UiBtn, UiIcon, UiBadge },

    props: {
      marketplace: { type: Object, default: () => {} },
      hasPreviousTrades: { type: Boolean, default: false },
      canViewMarketplace: { type: Boolean, default: false },
      hasMarketplace: { type: Boolean, default: false },
      applicationsCount: { type: Number, default: undefined },
      trialStage: { type: String, default: undefined },
    },

    computed: {
      applicationsCountText() {
        return `${this.applicationsCount} ${formatNumbers(this.applicationsCount, [
          'ставка',
          'ставки',
          'ставок',
        ])}`;
      },

      biddingsInfoText() {
        return this.marketplace.name === 'ЕФРСБ'
          ? 'Торги на ЕФРСБ'
          : `Торги на площадке ${this.marketplace.name}`;
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-and-bidding-info {
    padding-top: 24px;

    &__badge.ui-badge {
      width: fit-content;
      border: 1px solid c('gray20', 'light');
      background: c('gray5', 'light');
      padding: 1px 6px !important;
      border-radius: 4px;
    }
  }
</style>
