import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';
import { serialize } from 'object-to-formdata';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class TradesService extends BaseApi {
  async searchTrades(category, page) {
    const {
      data: { trades, pages, total },
    } = await this.fetch('trades_search', {
      search: { category, page },
    });
    return { trades, pages, total };
  }

  async getTrade(tradeId) {
    const { data } = await this.fetch('trade_by_id', null, {
      id: tradeId,
    });
    return data;
  }

  async bid(tradeId, price) {
    const { data: trade } = await this.fetch(
      'trade_bid',
      serialize({
        trade: {
          price,
        },
      }),
      {
        id: tradeId,
      }
    );
    return trade;
  }

  async checkout(tradeId, price) {
    const { data: trade } = await this.fetch(
      'trade_checkout',
      serialize({
        trade: {
          price,
        },
      }),
      {
        id: tradeId,
      }
    );
    return trade;
  }

  async reject(tradeId) {
    const { data: trade } = await this.fetch('trade_reject', null, {
      id: tradeId,
    });
    return trade;
  }
}

const service = new TradesService(apiSchema, axiosProvider);

export { TradesService };
export { service };
export default service;
