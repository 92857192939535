<template lang="pug">
ui-dialog(v-if="serviceName" v-model="isOpen" content-class="service-info")
  template(#header)
    ui-dialog-header(
      back-btn
      close-btn
      @click:back="clickAllServices"
      @click:close="close"
    )

  slot
  .d-flex.justify-end.pa-6(style="margin-bottom: 30px")
    // не можем использовать service.details_url из-за сериализации пути c доменом в ПК версии
    ui-btn(small third :href="linkToServiceLanding")
      | Подробнее о сервисе
      ui-icon(name="arrow-outline-right")

  template(#footer)
    slot(name="footer")
</template>

<script>
  import { UiBtn, UiIcon, UiDialog, UiDialogHeader } from '@/uikit';
  import { createLogger } from '@/uikit/util';
  const logger = createLogger('ServiceInfo');

  export default {
    components: {
      UiBtn,
      UiIcon,
      UiDialog,
      UiDialogHeader,
    },

    props: {
      value: { type: Boolean, default: false },
      assetId: { type: String, default: undefined },
      serviceName: { type: String, default: undefined },
      assetLongId: { type: String, default: undefined },
    },

    computed: {
      isOpen: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
      linkToServiceLanding() {
        if (this.serviceName === 'auto_credit') {
          return `${this.$path.toPhoenix()}${this.$path.service(this.serviceName, this.assetLongId)}`;
        }

        return this.$path.service(this.serviceName, this.assetId);
      },
    },

    methods: {
      close() {
        this.$emit('click:close');
      },

      clickAllServices() {
        logger.log('Клик Назад');
        this.$emit('click:all');
      },

      clickChat() {
        this.$emit('click:chat');
      },

      clickApply() {
        this.$emit('click:apply');
      },
    },
  };
</script>

<style lang="scss">
  .service-info {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    border-radius: 0;
    background-color: c('white');
  }

  .service-info__header {
    height: 48px;
    flex: 0 1 48px;
  }

  .service-info__content {
    overflow-y: auto;
    min-height: 0;
    flex-grow: 1;
  }

  .service-info__footer {
    flex-shrink: 1;
    padding: 12px 16px;
    background-color: c('white');
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
  }
</style>
