<template lang="pug">
ui-icon(
  v-bind="$attrs"
  original-color
  v-tooltip="{ content: isDutch ? 'Голландский аукцион' : 'Классический аукцион' }"
  :name="isDutch ? 'decrease' : 'increase'"
)
</template>

<script>
  import { UiIcon } from '@/uikit/';

  export default {
    name: 'trade-kind',
    components: { UiIcon },
    inheritAttrs: false,

    props: {
      kind: { type: String, required: true },
    },

    computed: {
      isDutch() {
        return this.kind == 'dutch';
      },
    },
  };
</script>

<style lang="scss">
  .trade-kind {
    content: '';
    display: block;

    &--default {
      @include svg-bg-mask-recolored('increase', c('green', 'dark'), light);
    }

    &--dutch {
      @include svg-bg-mask-recolored('decrease', c('red', 'dark'), light);
    }
  }
</style>
