<template lang="pug">
.asset-trade-card(:class="classes")
  ui-loader.mt-5.mb-10(v-if="loadingTrade" preloader text="Обновление статуса аукциона...")
  template(v-if="trade && !loadingTrade")
    //- таймер 48 часов
    .asset-price-info__timer.mb-6(v-if="trade.tracked_time && timer48Result")
      .d-flex.align-center.mb-2
        .icon.icon-timer.c-purple60.mr-1
        .text--normal
          span.text--w500 {{ timer48Result }}&nbsp;
          | до окончания аукциона
      v-progress-linear.mr-2(
        :value="timer48Percent"
        color="#9B53AE"
        rounded
        height="4"
      )
    .asset-price-info__timer.mb-6(
      v-if="!trade.tracked_time && trade.kind === 'default' && trade.start_date && trade.end_date && trade.status === 'running' && !trade.freezed"
    )
      .d-flex.align-center.mb-2
        ui-icon.c-purple60.mr-1(name="timer")
        .t-caption-normal.c-gray80
          span.t-caption-middle-bold {{ timeToEnd }} &nbsp;
          | до конца приема заявок
      v-progress-linear.mr-2(
        :value="progressValue"
        color="#9B53AE"
        rounded
        height="4"
      )

    .d-flex.align-center.mb-6(v-if="trade.status === 'finished'")
      ui-icon.mr-1.c-gray70(name="timer" size=20)
      .t-caption-normal.c-gray80 Аукцион завершен

    .d-flex.align-center.mb-6(v-if="trade.freezed")
      ui-icon.mr-2(name="help" size=20 v-tooltip="{ ref: 'FreezedTooltip', delay: 200 }")
      span(ref="FreezedTooltip")
        .t-caption-small.c-gray20 Ставки не принимаются на время заморозки. Так же есть возможность отменить свои активные ставки.
      .t-caption-normal.c-gray80 Аукцион заморожен

    my-trade-status(no-fill :state="trade.user_status")
      .asset-trade-card__price
        .flex-grow-1
          .t-caption-small.mb-2.c-gray80(v-if="!trade.user_status") {{ trade.status === "finished" ? "Цена закрытия" : "Текущая цена" }}
          .asset-price.asset-price--large(:class="{ 'mt-6': trade.user_status }") {{ trade.current_price | currency }}
        .flex-grow-1(v-if="trade.start_price")
          .t-caption-small.c-gray70.mb-3 Начальная цена
          .asset-price.asset-price--medium.c-gray80 {{ trade.start_price | currency }}

    .asset-trade-card__price.mt-5(v-if="trade.status === 'running'")
      div
        .t-caption-small.c-gray70.mb-3 {{ trade.applications_count ? "Следующая ставка" : "Первая ставка" }}
        .asset-price.asset-price--medium.c-gray80 {{ trade.next_step_price | currency }}

      div(v-if="viewCheckoutButton")
        .t-caption-small.c-gray70.mb-3 Экспресс-цена
        .asset-price.asset-price--medium.c-gray80 {{ trade.next_checkout_price | currency }}

    .mt-6(v-if="(viewStepButton || viewCheckoutButton) && trade.status === 'running'")
      ui-btn.mb-3(
        v-if="viewStepButton"
        large
        secondary
        target="_blank"
        width="100%"
        @click="placeBidStep"
        :loading="loading"
        :disabled="isAuthenticated && !canBidStep"
      )
        span Сделать ставку
        //- ui-icon.position-right(name="help" color="#C8CAD1")
          //- style="background-color: #a0a4a8 !important"

      //- ui-btn(
      //- v-if="viewStepButton"
      //- large
      //- secondary
      //- width="100%"
      //- @click="placeBidStep"
      //- :disabled="isAuthenticated && !canBidStep"
      //- )
      //- ui-icon(name="plus" left)
      //- | Сделать ставку

      ui-btn.mb-3(
        v-if="viewCheckoutButton"
        large
        secondary
        width="100%"
        @click="placeBidCheckout"
        :loading="loading"
        :disabled="isAuthenticated && !canBidCheckout"
      )
        span Купить сейчас
        //- ui-icon.position-right(name="help" color="#C8CAD1")

      //- ui-btn.mt-2(
      //- v-if="viewCheckoutButton"
      //- large
      //- secondary
      //- width="100%"
        //- @click="placeBidCheckout"
        //- :disabled="isAuthenticated && !canBidCheckout"
      //- )
      //- ui-icon(name="auction" left)
      //- | Купить сейчас

    ui-btn.mb-3(
      v-if="trade.freezed && canReject && trade.status === 'running'"
      large
      secondary
      width="100%"
      @click="bidsReject"
      :loading="loading"
      :disabled="isAuthenticated && !canReject"
    )
      span Отменить ставки

    asset-trade-bid-dialog(ref="assetTradeBidDialog" bottom-sheet)
    asset-trade-history-dialog(ref="assetTradeHistoryDialog" :trades="trade.applications")
</template>

<script>
  import { UiBtn, UiIcon, UiLoader } from '@/uikit/';
  import MyTradeStatus from '@/components/asset/components/MyTradeStatus.vue';
  import TradeKind from '@/components/asset/components/TradeKind.vue';
  import AssetTradeBidDialog from './AssetTradeBidDialog.vue';
  import AssetTradeHistoryDialog from './AssetTradeHistoryDialog.vue';

  import { formatNumbers } from '@/uikit/filters/pluralize';
  import { service as API } from '@/services/trades';
  import { handleError } from '@/libs/handle-error';

  export default {
    name: 'asset-trade-card',
    components: {
      UiBtn,
      UiIcon,
      UiLoader,
      AssetTradeBidDialog,
      MyTradeStatus,
      TradeKind,
      AssetTradeHistoryDialog,
    },

    props: {
      value: { type: Object, default: () => {} },
      assetId: { type: String, default: undefined },
      tradeId: { type: String, default: undefined },
    },

    data() {
      return {
        loading: false,
        loadingTrade: false,
        timer48Result: '',
      };
    },

    computed: {
      classes() {
        return {};
      },

      trade: {
        set(trade) {
          this.$emit('input', trade);
        },

        get() {
          return this.value;
        },
      },

      isAuthenticated() {
        return !!this.$store.getters['currentUser'];
      },

      canBidStep() {
        return this.trade?.politics?.step === true;
      },

      canBidCheckout() {
        return this.trade?.politics?.checkout === true;
      },

      canReject() {
        return this.trade?.politics?.reject === true;
      },

      viewCheckoutButton() {
        return this.trade?.politics?.view_checkout_button === true;
      },

      viewStepButton() {
        return this.trade?.politics?.view_step_button === true;
      },

      progressValue() {
        const totalMS = new Date(this.trade.end_date) - new Date(this.trade.start_date);
        const totalDays = totalMS / (1000 * 60 * 60 * 24);

        const currentMS = new Date() - new Date(this.trade.start_date);
        const curentDays = currentMS / (1000 * 60 * 60 * 24);

        const percent = (curentDays / totalDays) * 100;

        return Math.round(percent);
      },

      timer48Percent() {
        // 172800000 === 48 часов
        return 100 - Math.round(((this.trade.tracked_time - new Date()) / 172800000) * 100);
      },

      timeToEnd() {
        // get total seconds between the times
        let delta = Math.abs(this.trade.end_date - new Date()) / 1000;

        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;

        const daysText = `${days} ${formatNumbers(days, ['день', 'дня', 'дней'])}`;
        const hoursText = `${hours} ${formatNumbers(hours, ['час', 'часа', 'часов'])}`;

        if (days <= 0) {
          return hoursText;
        } else if (hours <= 0) {
          return daysText;
        } else {
          return `${daysText} ${hoursText}`;
        }
      },
    },

    watch: {
      trade() {
        // если появилось конечное время аукциона - запускаем таймер
        if (this.trade && this.trade.tracked_time) {
          this.runTimer48();
          setInterval(() => {
            this.runTimer48();
          }, 10000);
        }
      },
    },

    mounted() {
      this.fetchTrade();
    },

    methods: {
      async fetchTrade() {
        if (this.tradeId) {
          this.loadingTrade = true;
          this.trade = await API.getTrade(this.tradeId);
          this.loadingTrade = false;
        }
      },

      runTimer48() {
        this.timer48Result = this.timer48();
      },

      timer48() {
        const endTime = this.trade.tracked_time;
        const diff = endTime - new Date();
        // часы
        const hours = Math.floor(diff / 3600000);
        const hoursText = `${hours} ${formatNumbers(hours, ['час', 'часа', 'часов'])}`;
        
        // если время таймера прошло - не показываем (случай, когда тик по таймеру ещё не сработал)
        if (diff < 0) return null;

        // минуты
        const estimateMinutes = diff - hours * 3600000;
        const minutes = Math.floor(estimateMinutes / 60000);
        const minutesText = `${minutes} ${formatNumbers(minutes, ['минута', 'минуты', 'минут'])}`;

        console.log('hours', hours);
        console.log('minutes', minutes);
        if (hours <= 0) {
          return minutesText;
        } else if (minutes <= 0) {
          return hoursText;
        } else {
          return `${hoursText} ${minutesText}`;
        }
      },

      async placeBidStep() {
        if (!this.isAuthenticated) {
          this.$emit(
            'notify:info',
            'Для участия в аукционе необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        try {
          const confirm = await this.$refs.assetTradeBidDialog.open(this.trade, {
            confirmText: 'Сделать ставку на шаг',
            title: 'Сделать ставку',
            priceText: 'Ваша ставка',
            price: this.trade.next_step_price,
            width: '100%',
          });
          if (!confirm) return;

          this.loading = true;
          let trade = null;
          trade = await API.bid(this.tradeId, this.trade.next_step_price);
          this.trade = trade;
          this.$emit('notify:success', 'Ваша ставка успешно принята!');
          this.loading = false;
        } catch (error) {
          const msg = error.data?.errors?.base;
          handleError(error);
          this.$emit('notify:error', msg);
        }
      },

      async placeBidCheckout() {
        if (!this.isAuthenticated) {
          this.$emit(
            'notify:info',
            'Для участия в аукционе необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        try {
          const confirm = await this.$refs.assetTradeBidDialog.open(this.trade, {
            confirmText: 'Сделать ставку по экспресс-цене',
            title: 'Купить сейчас',
            priceText: 'Экспресс-цена',
            price: this.trade.next_checkout_price,
            width: '100%',
          });
          if (!confirm) return;

          this.loading = true;
          let trade = null;
          trade = await API.checkout(this.tradeId, this.trade.next_checkout_price);
          this.trade = trade;
          this.$emit('notify:success', 'Ваша ставка успешно принята!');
          this.loading = false;
        } catch (error) {
          const msg = error.data?.errors?.base;
          handleError(error);
          this.$emit('notify:error', msg);
        }
      },

      async bidsReject() {
        if (!this.isAuthenticated) {
          this.$emit(
            'notify:info',
            'Для участия в аукционе необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        this.loading = true;
        let trade = null;
        trade = await API.reject(this.tradeId);
        this.trade = trade;
        this.$emit('notify:success', 'Ваши ставки успешно отклонены!');
        this.loading = false;
      },

      async openHistory() {
        if (!this.isAuthenticated) {
          this.$emit(
            'notify:info',
            'Для просмотра истории ставок аукциона необходимо войти в систему или зарегистрироваться'
          );
          return;
        }

        await this.$refs.assetTradeHistoryDialog.open();
      },

      async openPreviousAuctions() {
        await this.$refs.assetTradePreviousDialog.open();
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-card {
    display: flex;
    flex-direction: column;

    .asset-trade-card__info {
      display: flex;
    }

    .asset-trade-card__price {
      display: flex;
      position: relative;
      flex: 0 0;

      > * {
        flex-basis: 50%;
      }
    }

    .asset-trade-card__price.my-trade-status--default {
      padding-top: 12px;
    }

    .asset-trade-card__price.my-trade-status--default + .asset-trade-card__bid,
    .asset-trade-card__bid + .asset-trade-card__bid-checkout {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 64px);
        border-top: 1px solid c('black10', 'light');
      }
    }

    .asset-trade-card__applications {
      padding: 4px 8px;
      border-radius: 4px;
      background-color: c('light', 'light');
    }
  }

  .trade-kind-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 4px;
    border-radius: 4px;
    background-color: c('light', 'light');
    font-size: 13px;
  }
</style>
