<template lang="pug">
v-fade-transition
  .asset-stat-counter(v-if="!loading" v-on="$listeners" v-bind="$attrs")
    .align-center.mb-3
      ui-icon.mr-2(:name="icon")
      .text--h3 {{ count }}
    .span.pr-6 {{ text }}
</template>

<script>
  import { UiIcon, UiLoader } from '@/uikit';

  export default {
    name: 'asset-stat-counter',
    components: { UiIcon, UiLoader },
    props: {
      icon: { type: String, required: true },
      count: { type: Number, required: true },
      text: { type: String, required: true },
      loading: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss">
  .asset-stat-counter {
    @include transition;
    @include text--small;
    width: 120px;
    min-height: 64px;
    color: c('text', 'light');

    &__icon {
    }

    &__count {
    }

    &__text {
      margin-right: 24px;
    }
  }
</style>
