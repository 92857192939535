<template lang="pug">
.asset-photo-slider
  .asset-photo-slider__header
    v-toolbar(color="transparent" elevation="0")
      v-spacer
      span {{ currentSlide + 1 + "/" + photos.length }}
      v-spacer
      ui-icon(name="close" @click="$emit('close', currentSlide)")
  v-carousel.asset-photo-slider__slider(v-model="currentSlide" hide-delimiters)
    v-carousel-item.slider-item(
      v-for="(photo, i) in photos"
      :key="'photo' + i"
      eager
      @click="fullscreen = true"
    )
      img.slider-item__photo(:key="'photo-img' + i" :src="photo" rel="preload")
  .asset-photo-slider__actions
    slot(name="footer")
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';

  export default {
    name: 'asset-photo-slider',
    components: { UiBtn, UiIcon },

    props: {
      value: { type: Number, default: 0 },
      photos: { type: Array, default: () => [] },
    },

    computed: {
      currentSlide: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },
  };
</script>

<style lang="scss">
  .asset-photo-slider {
    &__header {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
    }

    &__slider {
      .slider-item__photo {
        width: 100%;
      }
    }

    &__actions {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 32px 16px;

      & .btn-primary.v-btn {
        height: 44px !important;
        border-radius: 8px;

        &.asset-phone-button--done {
          color: white;
          width: auto !important;

          & .icon {
            background-color: white !important;
          }
        }
      }
    }
  }
</style>
