<template lang="pug">
.asset-category
  slot
</template>

<script>
  export default {
    name: 'asset-category',
  };
</script>

<style lang="scss">
  .asset-category {
    @include text-style('caption-small', 'light');
    position: relative;
    padding-left: 20px;
    color: c('black80', 'light');

    &::before {
      @include svg-bg-mask-recolored('catalog-list-16', currentColor, light);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
    }
  }
</style>
