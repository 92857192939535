<template lang="pug">
.asset-trade-history-item(:class="itemColor")
  .d-flex.justify-space-between.align-center.mb-2
    div
      .t-button-xxsmall.c-text--dimmed(v-if="trade.my") Ваша ставка
      .asset-trade-history-item-title {{ trade.value_formatted }}
    .t-caption-middle.c-text--dimmed {{ trade.created_at | datetime }}
  ui-expansion-panel(:class="{ 'ui-expansion-panel--no-chevron': noChevron }")
    template(#header)
      .t-caption-normal.c-text.text--truncate(style="width: 200px") {{ trade.status_group_text }}
    .t-caption-small.my-1.c-text(v-if="trade.rejection_reason") {{ trade.rejection_reason }}
    .d-flex.align-center.mt-1(v-if="trade.timer && timerDuration(trade.timer)")
      ui-icon.c-black70.mr-1(name="time" size="16")
      .t-caption-small.c-text--dimmed
        ui-countdown-timer(#default="{ timeLeft }" :duration="timerDuration(trade.timer)") {{ timeLeft | duration }}
      ui-icon.ml-1.c-text--dimmed(
        name="help"
        right
        size=16
        v-tooltip="{ content: 'Время, через которое Банк приступит к рассмотрению данной заявки в качестве победителя. После рассмотрения заявки Банком, сотрудники свяжутся с вами и сообщат о статусе рассмотрения' }"
      )
</template>

<script>
  import { UiBtn, UiIcon, UiExpansionPanel, UiCountdownTimer } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon, UiExpansionPanel, UiCountdownTimer },
    props: {
      trade: { type: Object, default: () => {} },
    },

    computed: {
      itemColor() {
        let status;
        if (this.trade.my) {
          switch (this.trade.status_group) {
            case 'created':
              status = 'asset-trade-history-item_created';
              break;
            case 'rejected':
              status = 'asset-trade-history-item_reject';
              break;
            case 'progress':
              status = 'asset-trade-history-item_progress';
              break;
            default:
              status = '';
          }
        }

        return status;
      },

      noChevron() {
        return this.trade.rejection_reason === null || !this.trade.timer;
      },
    },

    methods: {
      timerDuration(endTimestamp) {
        const duration = Math.floor((endTimestamp - Date.now()) / 1000);
        return duration >= 0 ? duration : undefined;
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-history-item {
    padding: 8px 16px 20px;
    border-bottom: 1px solid c('gray10', 'light');

    .ui-expansion-panel--no-chevron {
      .ui-dropdown-button__right {
        display: none;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: c('black100', 'light');
      position: relative;
    }
    &-subtitle {
      position: absolute;
      left: 0;
      top: -8px;
    }

    &_reject {
      background-color: c('red--lightest', 'light');

      .asset-trade-history-item__text {
        color: c('red', 'light');
      }
    }

    &_created {
      background-color: c('light', 'light');
    }

    &_progress {
      background-color: c('primary--lightest', 'light');

      .asset-trade-history-item__text {
        color: c('primary', 'light');
      }
    }
  }
</style>
