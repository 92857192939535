<template lang="pug">
.asset-primary-fields
  .d-flex.align-center.mb-2
    ui-icon.mr-2.c-primary60(name="case-check")
    .t-middle-bold.c-gray100 Готовый бизнес

  .d-flex.align-center
    ui-icon.mr-2.c-primary60(name="rouble-cycle")
    .t-middle-bold.c-gray100 Выручка LTM:&nbsp;
      span.t-middle {{ businessRevenue ? businessRevenueText : "не указано" }}

  //- .asset-info__primary-features__item.asset-info__primary-features__item--big(
  //- v-for="item in primaryFields"
  //- v-if="primaryFields.length"
  //- )
  //- ui-icon.mr-2.c-gray70(name="asset-search-2")
  //- .asset-info__primary-features__item-text
  //- .t-caption-middle.c-gray70.mb-auto {{ item.name }}
  //- h5.c-gray100.mt-1 {{ item.value }}
</template>

<script>
  import { UiIcon } from '@/uikit';

  export default {
    name: 'asset-primary-features-list',
    components: { UiIcon },

    props: {
      businessRevenue: { type: Number, default: undefined },
      businessRevenueText: { type: String, default: undefined },
    },
  };
</script>
