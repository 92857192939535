<template lang="pug">
ui-btn.asset-service-button(
  :class="{ 'asset-service-button--phone': showPhone }"
  v-if="communicationMethod !== 'phone'"
  icon
  large
  primary
  @click="submit"
)
  ui-icon.mr-3(:name="sellerSber ? 'message2' : 'phone'")
  template(v-if="showPhone")
    a.t-caption-middle-bold.c-gray100(href="tel:+78007700772" style="text-decoration: none") 8 800 770 07 72
  template(v-else)
    .t-button-large.c-gray100 Получить консультацию
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { mapGetters } from 'vuex';

  export default {
    name: 'chat-ask-button-sberlot',
    components: { UiBtn, UiIcon },

    props: {
      assetId: { type: String, default: '' },
      canAsk: { type: Boolean, default: false },
      sellerSber: { type: Boolean, default: false },
      text: { type: String, default: undefined },
      contactType: { type: String, default: undefined },
      communicationMethod: { type: String, default: '' },
    },

    data() {
      return {
        showPhone: false,
      };
    },

    computed: {
      ...mapGetters(['currentUser']),
    },

    methods: {
      submit() {
        if (!this.currentUser) {
          this.$emit('error:auth');
        }

        if (this.sellerSber) {
          this.$emit('click');
        } else {
          this.showPhone = true;
        }
      },
    },
  };
</script>

<style lang="scss">
  .asset-service-button.theme--light.btn-primary.v-btn {
    background-color: c('primary10', 'light') !important;
    color: c('gray100', 'light') !important;
    width: 100%;

    &.asset-service-button--phone {
      background-color: c('gray5', 'light') !important;
      border: 1px solid c('gray10', 'light') !important;

      &:hover {
        color: c('gray100', 'light') !important;
        .icon {
          background-color: c('primary60', 'light') !important;
        }
      }
    }

    .icon {
      background-color: c('primary60', 'light') !important;
    }

    &:hover {
      .icon {
        background-color: c('white', 'light') !important;
      }
    }

    .t-caption-middle-bold {
      font-size: 18px;
      color: c('gray100', 'light');
    }
  }
</style>
