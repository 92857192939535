<template lang="pug">
.asset-item.d-flex
  .asset-item__photo.flex-grow-0.flex-shrink-1
    v-img.rounded.mr-4(
      :src="photoMain"
      width=64
      height=64
    )
  .asset-item__info.flex-grow-1
    .text--small.c-text--dimmed.mb-1 {{ asset.kind_name }}
    .text--h6.mb-1 {{ asset.name }}
    .text--small.c-text.mb-1 {{ asset.address }}
</template>

<script>
  import assetDefaultImage from '@/assets/default-asset-img.png';

  export default {
    name: 'asset-item',
    props: {
      asset: { type: Object, default: () => {} },
      // photos: { type: Array, default: () => [] },
      // name: { type: String, default: '' },
      // kindName: { type: String, default: '' },
    },

    computed: {
      photoMain() {
        return this.hasPhotos ? this.photos[0] : this.photoDefault;
      },

      photoDefault() {
        return assetDefaultImage;
      },
    },
  };
</script>
