<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="asset-trade-history-dialog"
  fullscreen
  hide-overlay
  persistent
  transition="dialog-bottom-transition"
)
  v-toolbar.asset-trade-history-dialog__toolbar(flat height=48)
    v-spacer
    v-toolbar-title.t-h3-mobile История ставок
    v-spacer
    ui-btn(text width=32 @click="cancel")
      ui-icon(name="close")

  .asset-trade-history-dialog__content
    .asset-trade-history__chart.mb-6
      asset-price-chart(:price-data="priceData")
    .asset-trade-history-list(v-if="trades.length")
      asset-trade-history-item(v-for="(item, index) in trades" :key="index" :trade="item")
    .asset-trade-history-dialog__empty(v-else)
      .t-h3-mobile.mb-2 Нет ставок
      .t-middle По данному аукциону еще не было сделано ни одной ставки
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import AssetTradeHistoryItem from './AssetTradeHistoryItem.vue';
  import { convertDateToMsk } from '@/uikit/util/convert-date';
  import AssetPriceChart from './AssetPriceChart.vue';

  export default {
    components: { UiBtn, UiIcon, AssetTradeHistoryItem, AssetPriceChart },
    props: {
      trades: { type: Array, default: undefined },
    },

    data() {
      return {
        isActive: false,
      };
    },

    computed: {
      priceData() {
        return this.trades?.map((a) => {
          return {
            x: this.convertDate(a.created_at),
            y: a.value / 1000000,
            amount: a.value_formatted,
            marker: { enabled: a.my },
          };
        });
      },
    },

    methods: {
      open() {
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },

      convertDate(date) {
        return convertDateToMsk(new Date(date));
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-history-dialog {
    background: c('white', 'light');

    &__loader {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    &__toolbar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 71px;
      background-color: c('white', 'light');
      z-index: 10;
      @include box-shadow('1', 'light');
    }

    &__content {
      padding: 0 16px;
    }

    &__empty {
      margin: 20px auto 0;
      text-align: center;
      max-width: 70%;
    }
  }
</style>
