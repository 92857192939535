<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .service-desc-header
    .service-desc-header__content
      .service-desc-header__title.c-gray100.mb-2 {{ service.title }}
      .service-desc-header__description.c-gray100 Защита интересов всех участников сделки с&nbsp;использованием аккредитива. Покупатель гарантированно получит недвижимость или&nbsp;товар, а&nbsp;продавец&nbsp;— деньги.
    .service-desc-header__icon
      img(src="~@/assets/96/buy.svg")

  .px-4
    .t-h3-mobile.c-gray100.mt-10.mb-5 Что такое аккредитив?
    .t-normal.c-gray80 <strong>Аккредитив</strong>&nbsp;— вид&nbsp;безопасного расчёта, при&nbsp;котором банк, действующий по&nbsp;поручению Покупателя, обязуется произвести платёж в&nbsp;пользу Продавца, при&nbsp;представлении документов, соответствующих условиям аккредитива.

    .t-h3-mobile.c-gray100.mt-10.mb-5 Преимущества
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="case-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Удобно
        .t-middle.c-gray80 Аккредитив можно открыть в&nbsp;любом офисе СберБанка или&nbsp;в&nbsp;мобильном приложении
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="shield-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Безопасно
        .t-middle.c-gray80 Гарантия защиты всем сторонам сделки, в&nbsp;том&nbsp;числе участникам цепочки сделок
    .d-flex
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="timer-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Быстро
        .t-middle.c-gray80 Открытие аккредитива в&nbsp;течение 10&nbsp;минут в&nbsp;офисе и&nbsp;1&nbsp;минуты онлайн, исполнение аккредитива в&nbsp;течение 1&nbsp;дня

    .t-h3-mobile.c-gray100.mt-12 Кредит предоставляет ПАО СберБанк
    img.mt-6(src="~@/assets/logos/sber-logo.svg")

  letter-of-credit-apply-dialog(ref="letterOfCreditApplyDialog" :asset-id="assetId")

  template(#footer)
    ui-btn(
      large
      primary
      width="100%"
      @click="openLetterOfCreditApplyDialog"
    )
      | Узнать подробнее
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import LetterOfCreditApplyDialog from '@/components/services/LetterOfCreditApplyDialog.vue';
  import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, LetterOfCreditApplyDialog },
    inject: ['requireAuth'],
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      canAsk: { type: Boolean, default: false },
    },

    methods: {
      openLetterOfCreditApplyDialog() {
        if (this.canAsk) {
          seoEventService.safeTriggerEvent(this.assetId, this.service.name, [
            'application',
            'web_mobile',
          ]);
          this.$refs.letterOfCreditApplyDialog.open();
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>
