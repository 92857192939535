<template lang="pug">
service-info.service-info__sberlot.c-text(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .px-4
    .t-h1-mobile.my-6.c-gray100 Оценка севоборота сельхоз земель за последние 5 лет
    .t-middle.mb-6 Решили приобрести сельскохозяйственные земли, но не уверены в той информации, которую предоставил ее бывший владелец?
    .t-normal-bold.c-gray100.mb-4 Мы поможем вам, если вы:
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Хотите быть уверены в том, что покупаете
      li.bullet-list__item.bullet-list__item--checked Хотите заранее спланировать работы на полях
      li.bullet-list__item.bullet-list__item--checked Не хотите тратить лишнее время и деньги на экспертизы

    .t-middle.mb-6 Искусственный интеллект и данные космических спутников предоставят вам полный анализ истории севооборота приобретаемого поля за последние 5 лет!
    .t-middle.mb-6 Оставьте заявку на портале, и мы оперативно свяжемся с вами. От вас не потребуется никаких данных кроме информации о выбранном участке и вашего желания получить объективную информацию о нем.
    .t-middle.mb-6 Сделайте первый шаг к информированной покупке прямо сейчас.

  template(#footer)
    .d-flex
      ui-btn.mr-2(
        large
        primary
        width="85%"
        @click="$emit('click:apply')"
      ) Заказать услугу

      asset-chat-button(large width="44" @click="$emit('click:chat')")
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import AssetChatButton from '@/components/asset/components/AssetChatButton.vue';
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, AssetChatButton },
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },
  };
</script>
