<template lang="pug">
.asset-price-chart
  transition(name="fade")
    .asset-price-chart-skeleton(v-if="isLoading")
      .asset-price-chart-skeleton__loader
        | Загрузка...

  highcharts.chart-price__highchart(
    v-if="priceData && priceData.length > 0"
    :callback="chartIsLoaded"
    :options="chartsOptions"
  )
</template>
<script>
  import Vue from 'vue';
  import { Chart } from 'highcharts-vue';

  export default {
    name: 'asset-price-chart',

    components: { highcharts: Chart },

    props: {
      priceData: { type: Array, default: () => [] },
      loading: { type: Boolean, default: true },
    },

    data() {
      return {
        isLoading: this.loading,
        options: {
          chart: {
            type: 'areaspline',
            height: 400,
            spacing: [10, 0, 10, 0],
            zoomType: 'x',
          },
          title: { text: '' },
          credits: { enabled: false },
          legend: { enabled: false },
          tooltip: {
            formatter: function () {
              const time = Vue.filter('dateFormat')(new Date(this.x), 'DD MMM, в HH:mm');
              return ['<b>' + time + '</b>'].concat(
                this.points
                  ? this.points.map(function (p) {
                      return p.point.amount;
                    })
                  : []
              );
            },
            split: true,
          },
          plotOptions: {
            areaspline: {
              step: 'left',
              color: 'rgba(83, 173, 89, 1)',
              fillColor: {
                linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
                stops: [
                  [0, 'rgba(83, 173, 89, 0)'],
                  [1, 'rgba(83, 173, 89, 0.2)'],
                ],
              },
              animation: false,
              lineWidth: 2,
              marker: { enabled: false, color: 'rgba(133, 92, 248, 1)' },
            },
          },
        },
      };
    },

    computed: {
      yAxis() {
        const axis = {
          title: 'Цена',
          labels: {
            enabled: false,
          },
          lineWidth: 1,
          plotBands: [],
          plotLines: [],
        };

        const min = this.minValue;
        if (min) axis.min = min;

        return axis;
      },

      xAxis() {
        const defaults = {
          snap: false,
          type: 'datetime',
          crosshair: { snap: false },
        };
        return defaults;
      },

      chartsOptions() {
        return {
          ...this.options,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          series: [{ data: this.priceData, name: 'Цена' }],
        };
      },

      minValue() {
        let values = [];

        if (this.priceData && this.priceData.length > 0) {
          values.push(this.priceData.reduce((min, point) => Math.min(min, point.y), Infinity));
        }

        if (this.startPrice) values.push(this.startPrice);

        const result = Math.min.apply(null, values);
        return result === Infinity ? null : result;
      },
    },

    methods: {
      chartIsLoaded() {
        this.isLoading = false;
      },
    },
  };
</script>

<style lang="scss">
  .asset-price-chart {
    position: relative;
    height: 400px;
    overflow: hidden;
  }

  .asset-price-chart__highchart {
    width: 100%;
    height: 400px;
  }

  .asset-price-chart-skeleton {
    display: flex;
    position: absolute;
    z-index: 6;
    align-items: center;
    width: 100%;
    height: 400px;
    border: 1px solid c('black40', 'light');
    border-radius: 4px;
    background-color: #fff;
  }

  .asset-price-chart-skeleton__loader {
    margin-right: auto;
    margin-left: auto;
    opacity: 0.25;
    color: #000;

    .svg-icon {
      width: 150px;
      height: 100px;
    }
  }
</style>
