<template lang="pug">
ui-btn.asset-phone-button(
  v-if="!phone"
  v-bind="$attrs"
  primary
  @click="showPhone"
  :loading="loading"
)
  ui-icon(:class="{ 'mr-2': !noText }" name="phone")
  div(v-if="!noText") Позвонить

ui-btn.asset-phone-button.asset-phone-button--done(
  v-else
  v-bind="$attrs"
  secondary
  :href="getPhone"
)
  ui-icon.c-primary60(name="phone" left)
  span {{ phone }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { service as API } from '@/services/assets';

  import { createLogger } from '@/uikit/util/logger';
  import { handleError } from '@/libs/handle-error';

  const logger = createLogger('AssetPhoneButton', 'orange');

  export default {
    components: { UiBtn, UiIcon },
    inject: ['requireAuth'],
    inheritAttrs: false,

    props: {
      assetId: { type: String, required: true },
      canViewPhone: { type: Boolean, required: true },
      noText: { type: Boolean, default: false },
    },

    data() {
      return {
        phone: null,
        loading: false,
      };
    },

    computed: {
      getPhone() {
        return `tel: ${this.phone}`;
      },
    },

    methods: {
      async showPhone() {
        if (!this.canViewPhone) {
          this.requireAuth('Для просмотра телефона необходимо войти или зарегистрироваться.');
          return;
        }

        this.loading = true;
        logger.log('Запрос телефонного номера контакта');

        try {
          const { data: contacts } = await API.fetch(
            'asset_get_contact_phone',
            {},
            {
              id: this.assetId, // подставить id в параметризуемый api url
            }
          );

          this.phone = contacts.phone ? contacts.phone : '';

          window.yaCounter49130266?.reachGoal('pokazattelefon'); // отправляем успешное событие в яндекс метрику
        } catch (error) {
          handleError(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .btn-primary.v-btn.asset-phone-button {
    background-color: c('primary10', 'light') !important;
    border: none !important;

    &.theme--dark .icon {
      background-color: c('primary60', 'light');
    }
  }

  .btn-primary.v-btn.asset-phone-button--done {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid c('primary60', 'light') !important;
    border-radius: 8px;
    background-color: c('primary60', 'light') !important;

    & > .asset-phone-button__icon {
      background-color: #52575c !important;
    }

    & > .asset-phone-button__text {
      flex-grow: 1;
      text-align: center;
      color: c('gray100', 'light') !important;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
    }
  }
</style>
