<template lang="pug">
ui-dialog(v-model="isOpen" content-class="asset-services-dialog")
  template(#header)
    ui-dialog-header(close-btn title="Все сервисы" @click:close="close")

  .asset-services-dialog__content(v-if="otherServices.length")
    service-card.mb-4(
      v-for="service in primaryServices"
      :key="service.name"
      no-tags
      :assetId="assetId"
      :service="service"
      @click="openService(service)"
    )
    service-card.mb-4(
      v-for="service in otherServices"
      :key="service.name"
      no-tags
      :assetId="assetId"
      :service="service"
      @click="openService(service)"
    )
</template>

<script>
  import { UiBtn, UiIcon, UiDialog, UiDialogHeader } from '@/uikit';
  import ServiceCard from '@/components/ServiceCard.vue';
  import { filterPrimary, filterOther, sortServices } from '@/services/services';
  import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('ServicesSidePanel', 'orange');

  export default {
    components: {
      UiDialog,
      UiDialogHeader,
      UiBtn,
      UiIcon,
      ServiceCard,
    },

    props: {
      assetId: { type: String, default: '' },
      services: { type: Array, default: () => [] },
      asset: { type: Object, default: () => {} },
    },

    data() {
      return {
        isOpen: false,
      };
    },

    computed: {
      primaryServices() {
        return sortServices(filterPrimary(this.services));
      },

      otherServices() {
        return sortServices(filterOther(this.services));
      },
    },

    methods: {
      open() {
        logger.log('Открыть все сервисы', this.services);
        this.isOpen = true;
      },

      close() {
        this.isOpen = false;
      },

      openService(service) {
        this.close();
        seoEventService.safeTriggerEvent(this.assetId, service.name, [
          'all_services',
          'web_mobile',
        ]);
        this.$emit('click:service', service);
      },
    },
  };
</script>

<style lang="scss">
  .asset-services-dialog {
    background-color: c('gray5');
  }

  .asset-services-dialog__content {
    padding: 16px;
  }
</style>
