<template lang="pug">
.ui-map
  viewport-size-observer(@resize:end="matchMapSize" @resize:start="onResize")
  transition(name="fade")
    .ui-map__skeleton(v-if="!isInitialized || isResizing")
      ui-loader(preloader)
  .ui-map__container(
    :class="{ 'ui-map__container--hidden': !isInitialized || isResizing }"
    ref="map_container"
  )
</template>

<script>
  import { UiLoader } from '@/uikit';
  import ViewportSizeObserver from './ViewportSizeObserver.vue';
  import debounce from 'underscore/modules/debounce.js';
  import ymaps from 'ymaps';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('');

  const DEBOUNCE_WAIT = 800;

  export default {
    name: 'asset-map',
    components: { ViewportSizeObserver, UiLoader },

    props: {
      address: { type: String, default: '' },
    },

    data() {
      return {
        isInitialized: false,
        isResizing: false,
        mapState: {
          // Порядок по умолчанию: «широта, долгота». Это Москва
          center: [55.753994, 37.622093],
          // от 0 (весь мир) до 19.
          zoom: 9,
          controls: [],
        },
        mapOptions: {
          suppressMapOpenBlock: true,
        },
        ymaps: null,
        settings: {
          apiKey: '72d90556-11a1-4f58-9ea9-31812eb6cfba',
          lang: 'ru_RU',
          coordorder: 'latlong',
          version: '2.1',
        },
      };
    },

    watch: {
      address(newVal) {
        this.dbSetMap(newVal);
      },
    },

    created() {
      this.dbSetMap = debounce(this.setMap, DEBOUNCE_WAIT);
    },

    mounted() {
      this.initMap();
    },

    beforeDestroy() {
      this.destroyMap();
    },

    methods: {
      async initMap() {
        try {
          // яндекс карта
          this.ymaps = await ymaps.load(this.yandexMapScriptLink(this.settings));

          this.ymaps.ready(() => {
            this.mapInstance = new this.ymaps.Map(
              this.$refs['map_container'],
              this.mapState,
              this.mapOptions
            );
            this.mapInstance.behaviors.disable('scrollZoom');

            if (this.address) {
              this.dbSetMap(this.address);
            }
          });
        } catch (error) {
          logger.error(error);
        }
      },

      yandexMapScriptLink(settings) {
        const {
          apiKey = '',
          lang = 'ru_RU',
          version = '2.1',
          coordorder = 'latlong',
          debug = false,
        } = settings;
        const mode = debug ? 'debug' : 'release';
        const settingsPart = `lang=${lang}${
          apiKey && `&apikey=${apiKey}`
        }&mode=${mode}&coordorder=${coordorder}`;
        return `//api-maps.yandex.ru/${version}/?${settingsPart}`;
      },

      destroyMap() {
        if (this.mapInstance) {
          this.mapInstance.destroy();
        }
      },

      onResize() {
        this.isResizing = true;
      },

      matchMapSize() {
        this.isResizing = false;

        if (this.mapInstance) {
          this.mapInstance.container.fitToViewport(true);
        }
      },

      setMap(address) {
        var that = this;
        this.ymaps
          .geocode(address, {
            results: 1,
          })
          .then(function (res) {
            that.mapInstance.geoObjects.each((geoObject) =>
              that.mapInstance.geoObjects.remove(geoObject)
            );
            // Выбирает первый результат геокодирования.
            let firstGeoObject = res.geoObjects.get(0);
            // Координаты геообъекта.
            // eslint-disable-next-line no-unused-vars
            let coords = firstGeoObject.geometry.getCoordinates();
            // Область видимости геообъекта.
            let bounds = firstGeoObject.properties.get('boundedBy');

            // Устанавливает необходимый для отображения текста пресет метки.
            firstGeoObject.options.set('preset', 'islands#darkBlueDotIconWithCaption');
            // Получает строку с адресом и выводим в иконке геообъекта.
            firstGeoObject.properties.set('iconCaption', firstGeoObject.getAddressLine());

            // Добавляет первый найденный геообъект на карту.
            that.mapInstance.geoObjects.add(firstGeoObject);
            // Масштабирует карту на область видимости геообъекта.
            that.mapInstance.setBounds(bounds, {
              // Проверяет наличие тайлов на данном масштабе.
              checkZoomRange: true,
            });

            // Добавляет +- на карту
            const zoomTopPosition = that.mapInstance.container.getSize()[1] / 2 - 30;
            that.mapInstance.controls.add('zoomControl', {
              size: 'small',
              position: {
                right: 10,
                top: zoomTopPosition,
              },
            });
          })
          .then(function () {
            that.isInitialized = true;
          });
      },
    },
  };
</script>

<style lang="scss">
  .ui-map {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .ui-map__container {
    z-index: 0;
    width: 100%;
    height: 100%;

    .ymaps-2-1-78-copyright__content {
      opacity: 0.1; // Кто тут хитрый жук?
    }
  }

  .ui-map__container.ui-map__container--hidden {
    position: absolute;
    left: -10000px;
    width: 100%;
    height: 100%;
  }

  .ui-map__skeleton {
    display: flex;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .ui-map__error {
    @include text--h3;
    color: red;
  }
</style>
