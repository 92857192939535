<template lang="pug">
.asset-recommended(v-if="assets.length")
  .t-h2-mobile.mb-4 Похожие активы
  .asset-recommended__grid
    asset-card-mini.asset-recommended__item(
      v-for="asset in shownAssets"
      :key="asset.short_id"
      new-window
      :asset="asset"
    )
  .d-flex.align-center.mt-5(v-if="assets.length > 4" @click="showAll = !showAll")
    .t-normal.c-gray80.mr-1.font-weight-light {{ showAll ? "Скрыть" : "Показать все" }}
    ui-icon(:name="showAll ? 'chevron-up' : 'chevron-down'")
</template>

<script>
  import AssetCardMini from '@/components/asset/AssetCardMini.vue';
  import { UiIcon } from '@/uikit';

  export default {
    components: {
      AssetCardMini,
      UiIcon,
    },

    props: {
      assets: { type: Array, default: () => [] },
    },

    data() {
      return {
        showAll: false,
      };
    },
    computed: {
      shownAssets() {
        if (this.showAll) {
          return this.assets;
        } else {
          return this.assets.length > 4 ? this.assets.slice(0, 4) : this.assets;
        }
      },
    },
  };
</script>

<style lang="scss">
  .asset-recommended {
    margin-top: 40px;

    &__grid {
      display: grid;
      overflow: hidden;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 16px;
    }

    &__item {
      text-decoration: none;
    }
  }
</style>
