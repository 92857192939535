<template lang="pug">
.asset-price-info(v-if="price || rent")
  .d-flex.align-center.mb-1(v-if="isPriceShow")
    .t-caption-small.c-gray80.mr-1 Индикативная стоимость
    .icon.icon-alert-round-full.c-gray40(v-tooltip="{ ref: 'IndicativeValueTooltip' }")
    span(ref="IndicativeValueTooltip") Не&nbsp;является публичной офертой, для уточнения стоимости, пожалуйста, обратитесь к&nbsp;нам.
  .asset-price.asset-price--large {{ price }}

  .asset-price-info__wrap(:class="{ 'mt-6': isPriceShow }" v-if="isRentShow")
    .t-caption-small.c-gray80.mb-1 Стоимость аренды
    .asset-price.asset-price--large(v-if="rent.cost_type === 'monthly'") {{ rent.cost + " / мес." }}
    .asset-price.asset-price--large(v-else-if="rent.cost && rent.period") {{ rent.cost + " за " + rent.period }} {{ rent.period | pluralize("ru", ["год", "года", "лет"]) }}
    .asset-price.asset-price--large(v-else) {{ rent.cost }}
    .mt-4.t-caption-normal.text-capitalize.c-gray80
      .t-caption-normal(v-if="rent && rent.period_text") Срок аренды:
        span.ml-1.c-gray100 {{ rent.period_text }}
      .t-caption-normal.mt-2(v-if="rent && rent.deposit") Депозит:
        span.ml-1.c-gray100 {{ rent.deposit }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: {
      UiBtn,
      UiIcon,
    },
    props: {
      price: { type: String, default: undefined },
      rent: { type: Object, default: () => {} },
      services: { type: Array, default: () => [] },
    },

    computed: {
      btnName() {
        if (this.price && this.rent.cost) {
          return 'покупку/аренду';
        } else if (this.price) {
          return 'покупку';
        } else {
          return 'аренду';
        }
      },

      isRentShow() {
        return this.rent?.cost;
      },

      isPriceShow() {
        return this.price;
      },
    },
  };
</script>

<style lang="scss">
  .asset-price-info {
    padding: 24px 0 8px;
    background: white;

    &__note {
      text-transform: capitalize;
    }

    .position-right {
      position: absolute;
      right: -15px;
    }
  }
</style>
