<template lang="pug">
.my-trade-status(:class="classes")
  ui-badge.my-trade-status__badge(v-if="userState" :color="userState.color") {{ userState.text }}
  .my-trade-status__content
    slot
</template>

<script>
  import { UiBadge } from '@/uikit/';

  const STATES = {
    leader: { state: 'leader', text: 'Вы лидер', color: 'green' },
    outbid: { state: 'outbid', text: 'Ставка перебита', color: 'red' },
    winner: { state: 'winner', text: 'Вы победитель', color: 'green' },
    looser: { state: 'looser', text: 'Вы проиграли', color: 'red' },
    waiting: { state: 'waiting', text: 'Определение победителя', color: 'orange' },
    ended: { state: 'ended', text: 'Аукион завешен', color: 'grey' },
    noFill: { state: 'ended', text: 'Аукион завешен', color: 'grey' },
  };

  export default {
    name: 'my-trade-status',
    components: { UiBadge },

    props: {
      state: { type: String, default: undefined },
      noFill: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        const basisClass = this.userState
          ? 'my-trade-status--' + this.userState.state
          : 'my-trade-status--default';

        return {
          'my-trade-status--no-fill': this.noFill,
          [basisClass]: true,
        };
      },

      userState() {
        return this.state ? STATES[this.state] : undefined;
      },
    },
  };
</script>

<style lang="scss">
  .my-trade-status {
    position: relative;
    min-height: 20px;

    .my-trade-status__badge {
      display: block;
      position: absolute;
      top: 0;
    }

    &--default {
      .my-trade-status__content {
        padding-top: 0;
      }
    }

    &--leader {
      background-color: #f1f8f2;

      .asset-price {
        color: c('primary', 'light');
      }
    }

    &--looser,
    &--outbid {
      background-color: #fef0ef;

      .asset-price {
        color: c('red60', 'light');
      }
    }

    &--winner {
      background-color: #eff6fe;
    }

    &--ended {
      background-color: c('light', 'light');
    }

    &--no-fill {
      background-color: transparent !important;
    }
  }
</style>
