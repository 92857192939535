<template lang="pug">
.asset-service-buttons(v-if="services.length")
  ui-btn.asset-service-btn.ui-append-icon(
    v-for="service in primaryServices"
    :key="service.name"
    large
    secondary
    width="100%"
    @click="openService(service)"
  )
    ui-icon.c-primary(:name="service.icon")
    span.ml-2 {{ service.shortTitle }}
    .ui-append-icon__icon
      ui-icon.c-gray70(name="chevron-right")

  ui-btn.asset-service-button.ui-append-icon.mt-3(
    v-if="services.length > primaryServices.length"
    large
    third
    width="100%"
    @click="openServicesPanel"
  )
    .asset-service-button__content
      .asset-service-button__title
        ui-icon.c-gray70(name="grid-view")
        span.ml-2 Все сервисы
      .asset-service-button__count.c-gray70 {{ services.length }}
    .ui-append-icon__icon
      ui-icon.c-gray70(name="chevron-right")
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { filterPrimary, sortServices } from '@/services/services';
  import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  const SERVICES = {
    sberlot: { title: 'Помощь агента на торгах', icon: 'account-circle' },
    enforcement_sberlot: { title: 'Купить с помощью агента', icon: 'account-circle' },
    agroai: { title: 'Оценить севооборот', icon: 'percent-circle' },
    credit: { title: 'Ипотека на выкуп с торгов', icon: 'percent-circle' },
    credit_drpa: { title: 'Получить кредит', icon: 'percent-circle' },
    credit_potential: { title: 'Расчёт кредитного потенциала', icon: 'percent-circle' },
    letter_of_credit: { title: 'Безопасная сделка', icon: 'account-circle' },
    auto_credit: { title: 'Оформить автокредит', icon: 'percent-circle' },
  };

  function buttonPresenter(s) {
    return {
      ...s,
      shortTitle: SERVICES[s.name]['title'],
      icon: SERVICES[s.name]['icon'],
    };
  }

  export default {
    components: { UiBtn, UiIcon },
    props: {
      assetId: { type: String, default: undefined },
      services: { type: Array, default: () => [] },
    },

    computed: {
      primaryServices() {
        return sortServices(filterPrimary(this.services)).map(buttonPresenter).slice(0, 2);
      },
    },

    methods: {
      openService(service) {
        seoEventService.safeTriggerEvent(this.assetId, service.name, ['button', 'web_mobile']);
        this.$emit('click:service', service);
      },

      openServicesPanel() {
        this.$emit('click:all');
      },
    },
  };
</script>

<style lang="scss">
  .asset-service-buttons {
    .asset-service-btn {
      position: relative;
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }

    .asset-service-btn.theme--light.btn-secondary.v-btn {
      justify-content: flex-start;
      padding: 10px 12px !important;

      .icon.icon-chevron-right {
        background-color: #a0a4a8 !important;
      }
    }
  }

  .asset-service-button {
    padding: 10px 40px 10px 12px !important;
  }

  .asset-service-button__content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .asset-service-button__title {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .asset-service-button__count {
    color: c('gray70', 'light');
  }
</style>
