<!-- eslint-disable prettier/prettier -->
<template lang="pug">
.asset-contact-info(v-if="sellerName || contact")
  .d-flex.align-baseline(v-if="sellerName")
    .asset-contact-info__seller-name.t-caption-normal-bold {{ sellerName }}
    .asset-contact-info__seller-count.text--medium.c-primary60.pl-1 {{ `${sellerAssetsCount}` | assetCount }}
  .d-flex.align-center(:class="{ 'mt-5': sellerName }" v-if="contact")
    .asset-contact-info__content
      .asset-contact-info__name {{ contact.name }}
    .asset-contact-info__avatar
      img.avatar(:src="contact.avatar" alt="")
</template>

<script>
  export default {
    name: 'asset-contact-info',
    props: {
      contact: { type: Object, default: undefined },
      sellerName: { type: String, default: undefined },
      sellerAssetsCount: { type: Number, default: undefined },
    },
  };
</script>

<style lang="scss">
  .asset-contact-info {
    &__seller-name {
      position: relative;
      flex-grow: 1;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 5px;
        width: 100%;
        margin-left: 2px;
        border-bottom: 1px dotted c('gray60', 'light');
      }
    }

    &__seller-count {
      width: 100%;
      max-width: fit-content;
      margin-top: auto;
    }

    &__content {
      flex-grow: 1;
    }

    &__avatar {
      flex-shrink: 1;
      width: 48px;

      .avatar {
        width: 48px;
        height: 48px;
        padding: 2px;
        border: 1px solid c('gray10', 'light');
        border-radius: 50%;
        vertical-align: middle;
      }
    }
  }
</style>
