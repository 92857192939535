export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    // Получить список аукционов, по критериям поиска
    // По умолчанию, возвращает 10 последних?
    trades_search: {
      request: {
        method: 'post',
        url: '/trades/search.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    // Найти аукцион по trade_id
    trade_by_id: {
      request: {
        method: 'get',
        url: '/trades/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    // Сделать ставку на шаг по аукциону
    trade_bid: {
      request: {
        method: 'post',
        url: '/trades/(:id)/bid.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    // Сделать ставку на шаг по аукциону по блиц-цене
    trade_checkout: {
      request: {
        method: 'post',
        url: '/trades/(:id)/checkout.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    // Отменить активные ставки
    trade_reject: {
      request: {
        method: 'post',
        url: '/trades/(:id)/reject.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};
