<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="asset-trade-previous-dialog"
  fullscreen
  hide-overlay
  persistent
  transition="dialog-bottom-transition"
)
  v-toolbar.asset-trade-previous-dialog__toolbar
    v-spacer
    v-toolbar-title.t-h3-mobile Информация о торгах
    v-spacer
    ui-btn(text width=32 @click="cancel")
      ui-icon(name="close")

  .asset-trade-previous-dialog__content
    template(v-if="bidding")
      .t-caption-small.c-black70.mb-1 Электронная Торговая Площадка (ЭТП)
      .t-caption-normal {{ bidding.name }}
      ui-btn.px-0.c-primary60.mt-4(
        v-if="biddingUrl"
        text
        :href="biddingUrl"
        target="_blank"
      )
        | Перейти на сайт ЭТП
        ui-icon.c-black70(name="open-window" right)

    .mt-9(v-if="bidding && bidding.lot")
      .t-h2-mobile.mb-4 Лоты на ЭТП
      asset-biddings-lot.mb-4(:lot="bidding.lot")

    .mt-9(v-if="actualEfrsbLots.length")
      .t-h2-mobile.mb-4 Лот на ЕФРСБ
      asset-actual-efrsb-lot.mb-4(v-for="lot in actualEfrsbLots" :key="lot.id" :lot="lot")

    //- .asset-trade-previous-list.mt-8(v-if="actualEfrsbLots.length")
    //- .asset-trade-previous-dialog__item(
    //- v-for="(item, index) in actualEfrsbLots"
    //- :key="index"
    //- :trade="item"
    //- )
    //- .d-flex.align-center.mb-4
    //- ui-icon.mr-1(
    //- size=24
    //- :name="item.kind === 'default' ? 'increase--colored' : 'decrease--colored'"
    //- )
    //- .t-caption-middle.c-black80 {{ item.start_date | dateFull }} - {{ item.end_date | dateFull }}
    //- .t-button-xsmall.c-black70 Цена закрытия
    //- .asset-trade-previous-dialog__price {{ item.end_price }}
    //- .t-caption-normal.c-black80 {{ item.user_status_text }}

    //- .asset-trade-previous-dialog__empty(v-else)
    //- .t-h3-mobile.mb-2 Нет ставок
    //- .t-middle По данному аукциону еще не было сделано ни одной ставки
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import AssetBiddingsLot from '@/components/asset/AssetBiddingsLot.vue';
  import AssetActualEfrsbLot from '@/components/asset/AssetActualEfrsbLot.vue';

  export default {
    components: { UiBtn, UiIcon, AssetBiddingsLot, AssetActualEfrsbLot },
    props: {
      biddings: { type: Object, default: () => {} },
    },

    data() {
      return {
        isActive: false,
        archivedEfrsbLots: [],
        actualEfrsbLots: [],
        bidding: undefined,
      };
    },

    computed: {
      biddingUrl() {
        return this.bidding && this.bidding.lot && this.bidding.lot.url
          ? this.bidding.lot.url
          : this.bidding.trade_url;
      },
    },

    mounted() {
      this.bidding = this.biddings.biddings || undefined;
      this.actualEfrsbLots = this.biddings.actual_efrsb_lots || [];
      this.archivedEfrsbLots = this.biddings.archived_efrsb_lots || [];
    },

    methods: {
      open() {
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-previous-dialog {
    background: c('white', 'light');

    &__loader {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    &__toolbar {
      z-index: 10;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) !important;
    }

    &__content {
      padding: 0 16px;
      margin-top: 24px;
    }

    &__empty {
      margin: 20px auto 0;
      text-align: center;
      max-width: 70%;
    }

    &__price {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: c('black80', 'light');
      margin: 2px 0 6px;
    }

    &__item {
      padding: 16px 0;
      border-bottom: 1px solid c('gray10', 'light');
    }
  }
</style>
