<template lang="pug">
.asset-bidding-info
  .asset-price-info__timer.mb-6(v-if="biddingStartDate && biddingEndDate")
    .d-flex.align-center.mb-2
      ui-icon.c-turquoise60.mr-1(name="timer")
      .t-caption-middle.c-gray80
        span.t-caption-middle-bold {{ timeToEnd }}&nbsp;
        | до конца приема заявок
    v-progress-linear.mr-2(
      :value="progressValue"
      color="#307D80"
      rounded
      height="4"
    )

  .d-flex.align-center.mb-6(v-if="waitBidding")
    ui-icon.mr-1.c-gray70(name="timer" size=20)
    .t-caption-normal.c-gray80 Торги пока не начались

  .d-flex.justify-space-between
    .asset-price-info__wrap
      .t-caption-small.c-gray80.mb-1 Текущая стоимость
      .asset-price.asset-price--large {{ price }}
    .asset-price-info__wrap.mr-4.d-flex.flex-column.justify-space-between(
      v-if="startPrice && discountPercentage"
    )
      .d-flex.align-baseline
        .t-caption-small.c-gray70.mb-1.mr-1 Начальная цена
        .asset-bidding-info__discount-badge.t-caption-small -{{ discountPercentage }}%
      .asset-price.asset-price--medium.c-gray70.text-decoration-line-through {{ startPrice }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { formatNumbers } from '@/uikit/filters/pluralize';

  export default {
    components: { UiBtn, UiIcon },
    props: {
      price: { type: String, default: undefined },
      startPrice: { type: String, default: undefined },
      waitBidding: { type: Boolean, default: undefined },
      biddingEndDate: { type: Number, default: undefined },
      biddingStartDate: { type: Number, default: undefined },
      discountPercentage: { type: Number, default: undefined },
      services: { type: Array, default: () => [] },
    },

    computed: {
      timeToEnd() {
        // get total seconds between the times
        let delta = Math.abs(this.biddingEndDate - new Date()) / 1000;

        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;

        const daysText = `${days} ${formatNumbers(days, ['день', 'дня', 'дней'])}`;
        const hoursText = `${hours} ${formatNumbers(hours, ['час', 'часа', 'часов'])}`;

        if (days <= 0) {
          return hoursText;
        } else if (hours <= 0) {
          return daysText;
        } else {
          return `${daysText} ${hoursText}`;
        }
      },

      progressValue() {
        const totalMS = new Date(this.biddingEndDate) - new Date(this.biddingStartDate);
        const totalDays = totalMS / (1000 * 60 * 60 * 24);

        const currentMS = new Date() - new Date(this.biddingStartDate);
        const curentDays = currentMS / (1000 * 60 * 60 * 24);

        const percent = (curentDays / totalDays) * 100;

        return Math.round(percent);
      },
    },
  };
</script>

<style lang="scss">
  .asset-bidding-info {
    padding: 24px 0 8px;
    background: white;

    &__discount-badge {
      padding: 1px 6px;
      background: c('red10', 'light');
      border: 1px solid c('red30', 'light');
      color: c('red80', 'light');
      border-radius: 4px;
    }

    .position-right {
      position: absolute;
      right: -15px;
    }
  }
</style>
