<template lang="pug">
ui-btn.asset-chat-button(v-on="$listeners" v-bind="$attrs" primary)
  ui-icon.c-white(name="message2")
  span.ml-1(v-if="text") {{ text }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon },
    inheritAttrs: false,

    props: {
      text: { type: String, default: undefined },
    },
  };
</script>
