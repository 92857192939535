<template lang="pug">
ui-dialog(v-model="isOpen")
  template(#header)
    ui-dialog-header(close-btn title="Связанные активы" @click:close="close")
  .asset-related-dialog__content
    asset-card.mb-4(v-for="(asset, i) in assets" :key="'asset' + i" :asset="asset")
</template>

<script>
  import AssetCard from '@/components/asset/AssetCard.vue';
  import { UiIcon, UiDialog, UiDialogHeader } from '@/uikit/components/';

  export default {
    components: { UiIcon, AssetCard, UiDialog, UiDialogHeader },

    props: {
      assets: { type: Array, default: () => [] },
    },

    data() {
      return {
        isOpen: false,
      };
    },

    methods: {
      open() {
        this.isOpen = true;
      },

      close() {
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss">
  .asset-related-dialog {
  }

  .asset-related-dialog__header {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    background-color: c('white', 'light') !important;
    z-index: 10;
  }

  .asset-related-dialog__content {
    background: c('gray5');
    padding: 72px 16px 24px 16px;
  }
</style>
