<template lang="pug">
service-info(
  v-on="$listeners"
  v-bind="$attrs"
  :asset-id="assetId"
  :service-name="service.name"
)
  .px-4.c-text
    .t-h1-mobile.my-6.c-gray100 Имущество на торгах в банкротстве можно купить в ипотеку
    .t-normal-bold.c-gray100.mb-4 Условия выдачи ипотеки*:
    ul.lined-list.t-middle.ml-2.mb-6
      li.lined-list__item Сумма ипотеки – от 300 тыс до 100 млн руб
      li.lined-list__item Срок – от 1 года до 30 лет
      li.lined-list__item Первоначальный взнос – от 10%
      li.lined-list__item Процентная ставка – от 22.9%
      li.lined-list__item Обеспечение – залог приобретаемого на торгах имущества

    .t-normal-bold.c-gray100.mb-4 Это безопасно
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Документы предварительно проверены Банком и арбитражным управляющим;
      li.bullet-list__item.bullet-list__item--checked Процедура торгов прозрачна и проводится на открытой электронной площадке в соответствии с требованиями Закона о банкротстве;
      li.bullet-list__item.bullet-list__item--checked Минимизированы риски оспаривания сделок наследниками, правопреемниками и иными третьими лицами, т.к. процедура торгов проводится в соответствии с Законом о банкротстве;
      li.bullet-list__item.bullet-list__item--checked Основные расчеты по сделке проводятся через аккредитив, который защищает покупателя (деньги перечисляются продавцу только после регистрации права собственности на покупателя);
      li.bullet-list__item.bullet-list__item--checked Имущество передается покупателю без арестов и залогов (за исключением оформляемой ипотеки в пользу банка).

    .t-normal-bold.c-gray100.mb-4 Это выгодно
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Имущество на торгах можно приобрести по более привлекательной цене (зачастую ниже рыночной);
      li.bullet-list__item.bullet-list__item--checked Не требуется дополнительных затрат на подготовку документов.

    .t-normal-bold.c-gray100.mb-4 Это удобно
    ul.bullet-list.t-middle.ml-2.mb-6
      li.bullet-list__item.bullet-list__item--checked Не нужно самостоятельно собирать документы по объекту недвижимости и подготавливать договор купли-продажи - это сделает арбитражный управляющий и сотрудник банка;
      li.bullet-list__item.bullet-list__item--checked Сотрудник банка поможет уточнить имеющиеся вопросы у арбитражного управляющего.

    .t-normal-bold.c-gray100.mb-4 Три основных шага со стороны заемщика
    ol.numbered-list.t-middle.mb-6
      li.numbered-list__item.mb-1 Заявка на ипотеку
      li.numbered-list__item.mb-1
        span Победа на торгах (Доступен сервис «
        a.link--no-underline(:href="$path.service('sberlot', assetId)" target="_blank") Агент на торгах
        span »)
      li.numbered-list__item Оформление сделки

    .t-normal-bold.c-gray100.mb-4 Особые условия:
    p.t-middle Невозможно использовать материнский капитал для первоначального взноса, а также не подходит под условия военной ипотеки. Материнский капитал может быть использован для погашения заемной части.

    p.t-xsmall * Не является публичной офертой. Финальные условия кредита определяются при заключении договора

  template(#footer)
    .d-flex
      ui-btn.mr-2(
        large
        primary
        width="85%"
        @click="openCreditApplication"
      ) Заказать услугу

      asset-chat-button(large width="44" @click="$emit('click:chat')")
</template>

<script>
  import ServiceInfo from '@/components/services/ServiceInfo.vue';
  import AssetChatButton from '@/components/asset/components/AssetChatButton.vue';
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    components: { UiBtn, UiIcon, ServiceInfo, AssetChatButton },
    inject: ['requireAuth'],
    inheritAttrs: false,

    props: {
      assetId: { type: String, default: undefined },
      service: { type: Object, default: undefined },
      sellerSber: { type: Boolean, default: false },
      canAsk: { type: Boolean, default: false },
    },

    methods: {
      openCreditApplication() {
        if (this.canAsk) {
          window.location = this.$path.creditServiceApplication(this.assetId);
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>
