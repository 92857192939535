<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="asset-trade-previous-dialog"
  fullscreen
  hide-overlay
  persistent
  transition="dialog-bottom-transition"
)
  v-toolbar.asset-trade-previous-dialog__toolbar
    v-spacer
    v-toolbar-title.t-h3-mobile Предыдущие аукционы
    v-spacer
    ui-btn(text width=32 @click="cancel")
      ui-icon(name="close")

  .asset-trade-previous-dialog__content
    .asset-trade-previous-list(v-if="trades.length")
      .asset-trade-previous-dialog__item(
        v-for="(item, index) in trades"
        :key="index"
        :trade="item"
      )
        .d-flex.align-center.mb-4
          ui-icon.mr-1(
            size=24
            :name="item.kind === 'default' ? 'increase--colored' : 'decrease--colored'"
          )
          .t-caption-middle.c-black80 {{ item.start_date | dateFull }} - {{ item.end_date | dateFull }}
        .t-button-xsmall.c-black70 Цена закрытия
        .asset-trade-previous-dialog__price {{ item.end_price }}
        .t-caption-normal.c-black80 {{ item.user_status_text }}

    .asset-trade-previous-dialog__empty(v-else)
      .t-h3-mobile.mb-2 Нет ставок
      .t-middle По данному аукциону еще не было сделано ни одной ставки
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon },
    props: {
      trades: { type: Array, default: () => [] },
    },

    data() {
      return {
        isActive: false,
      };
    },

    methods: {
      open() {
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .asset-trade-previous-dialog {
    background: c('white', 'light');

    &__loader {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    &__toolbar {
      z-index: 10;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) !important;
    }

    &__content {
      padding: 0 16px;
    }

    &__empty {
      margin: 20px auto 0;
      text-align: center;
      max-width: 70%;
    }

    &__price {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: c('black80', 'light');
      margin: 2px 0 6px;
    }

    &__item {
      padding: 16px 0;
      border-bottom: 1px solid c('gray10', 'light');
    }
  }
</style>
