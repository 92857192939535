<template lang="pug">
.asset-top-badge(:class="bgColor" v-if="type")
  .t-h5 {{ badge.title }}
  template(v-if="type !== 'rent'")
    ui-icon(name="help" size=24 v-tooltip="{ ref: 'TopBadgeTooltip', delay: 200 }")
    span(ref="TopBadgeTooltip") 
      .t-caption-middle-bold.c-gray5 {{ badge.title }}
      .asset-top-badge__tooltip-text.t-caption-small.c-gray20 {{ badge.text }}
      a.link.link--green(v-if="badgeLink" :href="badgeLink" target="_blank") Подробнее
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';

  export default {
    components: { UiIcon, UiBtn },
    props: {
      type: { type: String, default: '' },
    },

    data() {
      return {
        badgeTypes: {
          bidding_commerce: {
            color: 'turquoise',
            title: 'Коммерческие торги',
            text: 'Актив не находится в залоге. Торги проводятся на внешней электронной площадке. Победителем признается участник, предложивший наибольшую цену. После определения победителя можно сразу заключить договр купли-продажи с собственником актива и зарегистрировать право собственности.',
          },
          bidding_bankruptcy: {
            color: 'turquoise',
            title: 'Торги в банкротстве',
            text: 'Торги проводятся на внешней электронной площадке. Победителем признается участник, предложивший наиболее высокую цену. После заключения договора купли-продажи арбитражный управляющий поможет вам с процессом снятия обременения с собственности.',
            link: 'buying',
          },
          bidding_enforcement: {
            color: 'turquoise',
            title: 'Торги в испол. производстве',
            text: 'Торги проводятся на внешней электронной площадке. Победителем признается участник, предложивший наибольшую цену. После заключения договора купли-продажи с объекта снимается обременение и регистрируется право собственности.',
            link: 'buying',
          },
          sale: {
            color: 'blue',
            title: 'Прямая продажа',
            text: 'При покупке актива договор купли-продажи заключается напрямую с банком, компанией или иным собственником.',
            link: 'buying',
          },
          sale_or_rent: {
            color: 'blue',
            title: 'Прямая продажа / Аренда',
            text: 'При покупке или аренде актива договор купли-продажи заключается напрямую с банком, компанией или иным собственником.',
            link: 'buying',
          },
          rent: {
            color: 'blue',
            title: 'Аренда',
            text: 'При аренде актива договор купли-продажи заключается напрямую с банком, компанией или иным собственником.',
            link: 'buying',
          },
          trade_default: {
            color: 'purple',
            title: 'Аукцион на повышение',
            text: 'Аукцион проводится путем повышения начальной стоимости, указанной на Portal DA.',
            link: 'auction',
          },
          trade_dutch: {
            color: 'purple',
            title: 'Аукцион на понижение',
            text: 'Аукцион проводится путем повышения начальной стоимости, указанной на Portal DA. При отсутствии ставок цена снижается. Победителем признается участник, ставка которого была наибольшей в течение последних 48 часов.',
            link: 'auction',
          },
        },
      };
    },

    computed: {
      badge() {
        return this.badgeTypes[this.type];
      },

      bgColor() {
        return `asset-top-badge--bg-${this.badge.color}`;
      },

      badgeLink() {
        return this.badge.link ? `https://portal-da.ru/faq/${this.badge.link}` : false;
      },
    },
  };
</script>

<style lang="scss">
  .asset-top-badge {
    padding: 10px 16px;
    margin: 0 -16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      opacity: 0.8;
    }

    &__tooltip-text {
      margin: 4px 0 8px;
    }

    &--bg-turquoise {
      background: c('turquoise10', 'light');

      .t-h5 {
        color: c('turquoise80', 'light');
      }
      .icon {
        background-color: c('turquoise60', 'light');
      }
    }

    &--bg-blue {
      background: c('blue10', 'light');

      .t-h5 {
        color: c('blue80', 'light');
      }
      .icon {
        background-color: c('blue60', 'light');
      }
    }

    &--bg-purple {
      background: c('purple10', 'light');

      .t-h5 {
        color: c('purple80', 'light');
      }
      .icon {
        background-color: c('purple60', 'light');
      }
    }
  }
</style>
