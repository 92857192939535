<template lang="pug">
component(
  v-model="isActive"
  persistent
  :is="dialogComponent"
  :max-width="options.width"
  @keydown.esc="cancel"
)
  base-dialog-card(
    vertical-buttons
    :title="options.title"
    :width="options.width"
    @click:cancel="cancel"
  )
    ui-alert(v-if="trade" style="margin: -24px -32px 24px -32px" warning)
      .t-caption-small Делайте ставку, только если готовы купить актив. За ложную ставку предусмотрен бан аккаунта.
    template(v-if="trade && !hasAgreement")
      .t-caption-normal.mb-6 Для первой ставки необходимо подтвердить регламент.
      .d-flex.align-start.mb-10
        v-checkbox.ma-0.pa-0(
          v-model="agreementFlag"
          hide-details="auto"
          :error="hasError('agreement')"
        )
        .t-caption-normal(:class="hasAgreement ? 'c-black' : 'c-text'")
          | Я подтверждаю, что ознакомился с&nbsp;
          a.link.link-icon--small.c-primary.d-block(
            href=`${process.env.VUE_APP_API_HOST}/documents/regulations.pdf`
            style="width: fit-content"
            target="_blank"
          ) регламентом проведения торгов
          | &nbsp;и&nbsp;согласен с его условиями
      v-divider.mb-4

    template(v-if="trade")
      .d-flex
        .flex-grow-1
          .t-caption-small.c-text--dimmed.mb-2 Текущая цена
          .asset-price {{ trade.current_price | currency }}
        .flex-grow-1(v-if="trade.next_checkout_price")
          .t-caption-small.c-text--dimmed.mb-2 Экспресс-цена
          .asset-price {{ trade.next_checkout_price | currency }}

    template(#footer)
      .t-caption-small.c-text--dimmed.mb-2 {{ options.priceText }}
      .asset-price.asset-price--large.mb-4 {{ options.price | currency }}
    template(#confirm)
      ui-btn(
        large
        primary
        width="100%"
        @click="confirm"
        :disabled="!(agreementFlag || hasAgreement)"
      ) {{ options.confirmText }}
</template>

<script>
  import { UiBtn, UiAlert } from '@/uikit/';
  import { VDialog, VBottomSheet } from 'vuetify/lib';
  import BaseDialogCard from '@/components/BaseDialogCard.vue';

  import { formErrors } from '@/uikit/mixins/formErrors';

  export default {
    name: 'asset-trade-bid-dialog',
    components: { UiBtn, UiAlert, VDialog, VBottomSheet, BaseDialogCard },
    mixins: [formErrors],

    props: {
      bottomSheet: { type: Boolean, default: false },
    },

    data() {
      return {
        agreementFlag: false,
        isActive: false,
        resolve: null,
        reject: null,
        trade: null,
        options: {
          width: 416,
        },
      };
    },

    computed: {
      dialogComponent() {
        return this.bottomSheet ? 'v-bottom-sheet' : 'v-dialog';
      },

      hasAgreement() {
        return this.trade?.politics?.agreement === true;
      },
    },

    methods: {
      open(trade, options) {
        options = options || {};
        this.options = { ...this.options, ...options };

        this.isActive = true;
        this.trade = trade || null;
        this.agreementFlag = false;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },
    },
  };
</script>
