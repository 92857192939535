<template lang="pug">
.asset-borrowers-info
  v-window.asset-borrowers-info__items.mb-4(v-model="currentItem")
    v-window-item(v-for="(borrower, index) in items" :key="index")
      .asset-borrower-card
        .t-caption-small.c-black70.mb-1
          span {{ borrower.kind }}
          span(v-if="borrower.inn") &nbsp;—&nbsp;ИНН {{ borrower.inn }}
        .t-caption-normal.c-text {{ borrower.name }}

        template(v-if="borrower.trial_stage && borrower.trial_stage !== 'Отсутствует'")
          .t-caption-small.c-black70.mb-1.mt-5 Наличие взыскания / банкротства:
          .t-caption-normal.c-text {{ borrower.trial_stage }}

        template(v-if="borrower.beneficiary_warrants")
          .t-caption-small.c-black70.mb-1.mt-5 Наличие поручительств бенефициаров:
          .t-caption-normal.c-text {{ borrower.beneficiary_warrants }}

        template(v-if="borrower.shares_sale")
          .t-caption-small.c-black70.mb-1.mt-5 Согласен на продажу акций / долей в УК:
          .t-caption-normal.c-text {{ borrower.shares_sale }}

      template(v-if="borrower.creditors && borrower.creditors.length")
        .t-h3-mobile.mt-8.mb-3 Кредитный портфель заемщика
        .borrower-creditors-list
          .borrower-creditors-list__item(
            v-for="(creditor, index) in borrower.creditors"
            :key="index"
          )
            span {{ creditor.name }}
            span {{ creditor.amount }}

          .borrower-creditors-list__item.borrower-creditors-list__item--total
            span Общая сумма задолженности
            span {{ borrower.credit_amount }}

  slider-dots(v-if="items.length > 1" v-model="currentItem" :total="items.length")
</template>

<script>
  import SliderDots from '@/components/SliderDots.vue';

  export default {
    name: 'asset-borrowers-info',
    components: { SliderDots },
    props: {
      items: { type: Array, default: () => [] },
    },

    data() {
      return {
        currentItem: 0,
      };
    },
  };
</script>

<style lang="scss">
  .asset-borrowers-info {
  }

  .asset-borrowers-info__items {
    padding: 24px 16px;
    border-radius: 4px;
    background-color: c('white', 'light');
    border: 1px solid c('black10', 'light');
    box-shadow: shadow('xsmall', 'light');
  }

  .borrower-creditors-list {
  }
  .borrower-creditors-list__item {
    @include text-style('caption-normal', 'light');
    color: c('text', 'light');
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid c('black10', 'light');
    padding: 8px 0;
  }

  .borrower-creditors-list__item--total {
    font-weight: 500;
    border-bottom: none;
  }
</style>
