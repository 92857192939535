<template lang="pug">
.service-info-dialog
  component(
    v-if="service"
    v-model="isActive"
    :asset-id="assetId"
    :asset="asset"
    :can-ask="canAsk"
    :is="service.name"
    :seller-sber="sellerSber"
    :service="service"
    @click:all="clickAllServices"
    @click:apply="apply"
    @click:chat="openConversation"
    @click:close="close"
  )
</template>

<script>
  import { UiIcon, UiBtn, UiLoader } from '@/uikit';

  import agroai from './desc/agroai.vue';
  import sberlot from './desc/sberlot.vue';
  import enforcement_sberlot from './desc/enforcement_sberlot.vue';
  import credit from './desc/credit.vue';
  import credit_drpa from './desc/credit_drpa.vue';
  import credit_potential from './desc/credit_potential.vue';
  import letter_of_credit from './desc/letter_of_credit.vue';
  import auto_credit from './desc/auto_credit.vue';
  import { logger, service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiLoader,
      agroai,
      sberlot,
      enforcement_sberlot,
      credit,
      credit_drpa,
      credit_potential,
      letter_of_credit,
      auto_credit,
    },
    inject: ['requireAuth', 'openDesktop'],

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        loading: true,
        service: undefined,
        asset: undefined,
        assetId: undefined,
        canAsk: undefined,
        sellerSber: undefined,
        communicationMethod: undefined,
      };
    },

    methods: {
      open(service, asset) {
        this.service = service;
        this.asset = asset;

        this.assetId = asset?.short_id;
        this.canAsk = asset?.politics?.can_ask;
        this.sellerSber = asset?.seller === 'ПАО Сбербанк';
        this.communicationMethod = asset?.communication_method;

        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      close(res) {
        logger.log('Закрываем диалог сервиса');
        if (res) this.resolve(true);
        else this.resolve(false);

        this.isActive = false;
      },

      clickAllServices() {
        logger.log('Клик Назад в диалоге сервиса');
        this.$emit('click:all');
        this.close();
      },

      // общий метод подачи заявки — редирект на полную версию сайта
      apply() {
        if (this.canAsk) {
          seoEventService.safeTriggerEvent(this.assetId, this.service.name, [
            'application',
            'web_mobile',
          ]);

          this.openDesktop({
            path: this.service.application_url,
            text: 'Оформление заявки недоступно в мобильной версии. Для удобства, рекомендуем отрыть полную версию сайта',
          });
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },

      // общий метод контакта — открыть чат
      openConversation() {
        if (this.canAsk) {
          this.$router.push(this.$path.interactionDialog(this.assetId));
        } else {
          this.requireAuth('Чтобы задать вопрос необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>
